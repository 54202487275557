.principles-work {
	margin-bottom: vc(60);

	&__text {
		margin-bottom: vc(20);
		font-family: $font-secondary;
		font-weight: 300;
		font-size: vc(16);
		line-height: 150%;
		letter-spacing: 0.05em;
		text-transform: uppercase;

		@include tablet {
			margin-bottom: vc(40);
			font-size: vc(20);
		}
	}

	&__row {
		@include desktop {
			display: flex;
		}
	}

	&__image {
		margin-bottom: vc(20);
		width: 100%;

		img {
			height: vc(271);
			object-fit: cover;

			@include desktop {
				height: 100%;
			}
		}

		@include tablet {
			margin-bottom: vc(30);
		}

		@include desktop {
			flex-shrink: 0;
			margin-right: vc(30);
			margin-bottom: 0;
			width: vc(370);
		}
	}

	.swiper-wrapper {
		@include tablet {
			flex-wrap: wrap;
			margin: 0 vc(-15) vc(-30);
			width: auto;
			box-sizing: border-box;
		}
	}

	.swiper-slide {
		@include tablet {
			margin-bottom: vc(30);
			padding: 0 vc(15);
			width: 50%;
			height: auto;
		}
	}

	&__item {
		display: flex;
		min-height: vc(207);
		box-sizing: border-box;
		background: $color-white;

		&__icon {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: vc(15);
			background: $color-cerulean;

			svg {
				width: vc(40);
				height: vc(40);
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: vc(30);
			width: 100%;
		}

		&__title {
			margin: 0 0 vc(10);
			font-family: $font-secondary;
			font-weight: 300;
			font-size: vc(17);
			letter-spacing: 0.1em;
			text-transform: uppercase;

			@include tablet {
				font-size: vc(20);
			}
		}

		&__text {
			display: flex;
			align-items: center;
		}

		@include tablet {
			min-height: vc(204);
		}

		@include desktop {
			min-height: vc(180);
		}
	}
	&--become-dealer {
		.principles-work__item {
			min-height: vc(220);
		}
	}

	@include tablet {
		margin-bottom: vc(100);

		&--become-dealer {
			.swiper-slide {
				width: 100%;

				@include desktop {
					width: 50%;
				}
			}

			.principles-work__item {
				min-height: vc(170);
			}
		}
	}
}
