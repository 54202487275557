.articles {
	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vc(-15) vc(-30);
	}

	&__col {
		margin-bottom: vc(30);
		padding: 0 vc(15);
		width: 100%;

		@include tablet {
			width: 50%;
		}

		@include desktop {
			width: 33.33334%;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		height: 100%;

		&__image {
			margin-bottom: vc(15);

			img {
				width: 100%;
				height: vc(228);
				object-fit: cover;
			}

			@include tablet {
				margin-bottom: vc(20);
	
				img {
					height: vc(260);
				}
			}
		}

		&__title {
			margin: 0 0 vc(10);
			font-size: vc(16);
			color: #006BBB;
		}

		&__author {
			display: flex;
			align-items: center;
			margin: auto 0 vc(15);
			font-size: vc(14);

			svg {
				flex-shrink: 0;
				margin-right: vc(10);
				width: vc(20);
				height: vc(20);
			}
		}

		&__text {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			display: -webkit-box;
			overflow: hidden;
			font-size: vc(14);
			text-overflow: ellipsis;
		}
	}
}
