.upload {
	position: relative;
	padding: vc(60);
	width: vc(380);
	color: #111;

	&__input {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 5;
		margin: 0;
		outline: none;
		border: none;
		padding: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}

	&__title {
		margin: 0 0 vc(30);
		padding: 0 vc(10);
		font-size: vc(20);
		text-align: center;
	}

	&__icon {
		position: relative;
		margin: 0 auto;
		border: 1px dashed #ff474e;
		border-radius: vc(10);
		width: vc(60);
		height: vc(60);

		&::before,
		&::after {
			content: "";
			position: absolute;
			background: #ff474e;
		}

		&::before {
			left: vc(19);
			top: 50%;
			width: vc(20);
			height: 1px;
		}

		&::after {
			left: 50%;
			top: vc(19);
			width: 1px;
			height: vc(20);
		}
	}

	&__progress {
		&__text {
			overflow: hidden;
			margin-bottom: vc(12);
			font-size: vc(14);
			white-space: nowrap;
			text-overflow: ellipsis;
			color: #9f9f9f;
		}

		&__bar {
			border-radius: vc(10);
			height: vc(8);
			background: #eff7ff;

			span {
				display: block;
				border-radius: vc(10);
				width: 0;
				height: 100%;
				background: #ff474e;
			}
		}
	}

	&__text {
		margin-top: vc(30);
		font-size: vc(16);
		text-align: center;
		color: #9f9f9f;
	}

	&__button {
		margin-top: vc(30);
		text-align: center;

		button {
			outline: none !important;
			border: 1px solid #ff474e;
			padding: vc(9) vc(19);
			font-family: $font-secondary;
			font-size: vc(13);
			line-height: 1.5;
			text-align: center;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #ff474e;
			background: none;

			&:hover {
				border-color: #ff5d63;
				color: #ff5d63;
			}
		
			&:active {
				border-color: #ef3a41;
				color: #ef3a41;
			}
		}
	}
}
