.delivery {
	&__text {
		margin-bottom: vc(20);
	}

	&__quote {
		margin: vc(30) 0;

		@include tablet {
			margin: vc(40) 0;
		}
	}
}
