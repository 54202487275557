.article {
	&__content {
		width: 100%;
	}

	&__image {
		margin-bottom: vc(20);

		img {
			width: 100%;
		}

		@include tablet {
			margin-bottom: vc(40);
		}
	}

	&__title {
		margin-bottom: vc(10);
		padding-bottom: 0;
		color: $color-science-blue;

		@include tablet {
			margin-bottom: vc(20);
		}
	}

	&__user {
		display: flex;
		align-items: center;
		margin-bottom: vc(20);

		svg {
			margin-right: vc(10);
			width: vc(20);
			height: vc(20);
		}
	}

	&__text {
		p {
			margin: vc(20) 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5 {
			margin: vc(20) 0;
			font-family: $font-secondary;
			font-weight: 300;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: $color-science-blue;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h1 {
			margin: vc(40) 0;
			font-size: vc(26);
			letter-spacing: 0.1em;
	
			@include tablet {
				font-size: vc(30);
			}
		}

		h2 {
			font-size: vc(16);

			@include tablet {
				font-size: vc(20);
			}
		}

		h3 {
			font-size: vc(16);

			@include tablet {
				font-size: vc(19);
			}
		}

		h4 {
			font-size: vc(16);

			@include tablet {
				font-size: vc(18);
			}
		}

		h5 {
			font-size: vc(16);

			@include tablet {
				font-size: vc(17);
			}
		}

		h6 {
			font-size: vc(16);

			@include tablet {
				font-size: vc(16);
			}
		}

		img {
			display: block;
			margin: vc(20) 0;
			width: 100%;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			@include tablet {
				margin: vc(40) 0;
			}
		}
	}

	&__subtitle {
		color: $color-science-blue;
	}

	.table {
		margin-bottom: vc(40);

		@include tablet {
			margin-top: vc(40);
		}
	}

	&__link {
		text-align: left;

		a {
			padding-bottom: 0;
			min-width: auto;
		}
	}

	&__side {
		margin-top: vc(60);

		.title {
			margin-bottom: vc(40);
			color: #111;

			&__icon {
				svg {
					width: vc(40);
					height: vc(40);
				}
			}

			@include mobile {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		&__list {
			margin: 0 0 vc(-20);

			&__col {
				margin-bottom: vc(20);

				@include tablet {
					margin-bottom: vc(40);
					padding: 0 vc(15);
					width: 50%;
				}

				@include desktop {
					margin-bottom: 1px;
					padding: 0;
					width: 100%;
				}
			}

			@include tablet {
				display: flex;
				flex-wrap: wrap;
				margin: 0 vc(-15);
			}

			@include desktop {
				display: block;
				margin: 0;
			}
		}

		@include tablet {
			margin-top: vc(100);
		}

		@include desktop {
			flex-shrink: 0;
			margin-top: 0;
			margin-left: vc(130);
			width: vc(370);
		}
	}

	&__other {
		display: block;
		padding: vc(20);
		height: 100%;
		background: $color-white;

		&__title {
			margin-bottom: vc(10);
			color: $color-science-blue;
		}

		&__user {
			display: flex;
			align-items: center;
			margin-bottom: vc(20);
			font-size: vc(14);

			svg {
				margin-right: vc(10);
				width: vc(20);
				height: vc(20);
			}
		}

		&__text {
			font-size: vc(14);
			line-height: vc(21);
		}

		@include tablet {
			padding: vc(30);
		}
	}

	@include desktop {
		display: flex;
	}
}
