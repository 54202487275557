.breadcrumbs {
	margin-bottom: vc(20);
	font-size: vc(12);
	color: $color-tundora;

	ul {
		li {
			position: relative;
			display: inline;

			+ li {
				&::before {
					content: "/ ";
					margin: 0 vc(4);
				}
			}
		}
	}

	@include tablet {
		margin-bottom: vc(32);
	}
}
