.basket {
	&__remove {
		margin-bottom: vc(20);

		a {
			display: flex;
			align-items: center;
			font-size: vc(13);
			color: #006bbb;

			svg {
				flex-shrink: 0;
				margin-right: vc(5);
				width: vc(20);
				height: vc(20);
			}

			&:hover {
				color: #0078d1;
			}
		}
	}

	&__container {
		@include desktop {
			display: flex;
		}
	}

	&__content {
		width: 100%;
	}

	&__list {
		table {
			border-collapse: collapse;
			width: 100%;

			thead {
				display: none;

				@include tablet {
					display: table-header-group;
				}
			}

			th {
				padding-right: vc(10);
				padding-bottom: vc(10);
				padding-left: vc(10);
				font-weight: 400;
				font-size: vc(16);
				color: #4a4a4a;
				text-align: left;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}

				@include desktop {
					padding-right: vc(15);
					padding-left: vc(15);
				}
			}
		}
	}

	&__item {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		td {
			padding: 0;

			@include tablet {
				border-top: solid 1px #e0e0e0;
				padding-top: vc(20);
				padding-right: vc(10);
				padding-bottom: vc(20);
				padding-left: vc(10);
	
				&:first-child {
					padding-left: 0;
				}
	
				&:last-child {
					padding-right: 0;
				}
			}

			@include desktop {
				padding-right: vc(15);
				padding-left: vc(15);
			}
		}

		&__image {
			margin-bottom: vc(20);
			width: 100%;

			&__wrap {
				padding: vc(24) vc(22);
				height: vc(214);
				background: $color-white;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}

				@include tablet {
					padding: vc(10);
					width: vc(144);
					height: vc(90);
				}
			}

			@include tablet {
				margin-bottom: 0;
				width: auto;
			}
		}

		&__caption {
			margin-bottom: vc(20);
			width: 100%;

			@include tablet {
				margin-bottom: 0;
				width: vc(272);
			}

			@include desktop {
				width: vc(338);
			}
		}

		&__title {
			margin: 0;
			font-size: vc(16);
			text-transform: uppercase;
		}

		&__brand {
			margin-top: vc(10);
			font-size: vc(16);
			color: #4a4a4a;
		}

		&__counter {
			display: flex;
			align-items: center;
			width: 50%;

			&-wrap {
				width: 50%;

				@include tablet {
					width: auto;
				}
			}

			&__plus,
			&__minus {
				flex-shrink: 0;
				width: vc(24);
				height: vc(24);
				color: #006bbb;

				&:hover {
					color: #0078d1;
				}
			}

			&__field {
				flex-shrink: 0;
				outline: none;
				border: none;
				box-shadow: none;
				padding: 0;
				width: vc(48);
				height: vc(24);
				font-size: vc(16);
				text-align: center;
				background: transparent;
			}
		}

		&__price {
			width: 50%;
			font-size: vc(16);
			white-space: nowrap;

			@include tablet {
				width: auto;
			}
		}

		&__remove {
			position: absolute;
			right: 0;
			bottom: vc(50);

			a {
				display: block;
				width: vc(24);
				height: vc(24);
				color: #006bbb;

				&:hover {
					color: #0078d1;
				}
			}

			@include tablet {
				position: static;
				right: auto;
				bottom: auto;
			}
		}

		& + & {
			margin-top: vc(20);
		}

		@include tablet {
			display: table-row;

			& + & {
				margin-top:0;
			}
		}
	}

	&__side {
		margin-top: vc(40);

		&__content {
			border-radius: vc(5);
			padding: vc(40) vc(25);
			background: $color-white;

			@include tablet {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: vc(40) vc(30);
			}

			@include desktop {
				position: sticky;
				top: vc(30);
				display: block;
			}
		}

		@include tablet {
			margin-top: vc(20);
		}

		@include desktop {
			flex-shrink: 0;
			margin: 0 0 0 vc(70);
			width: vc(300);
		}
	}

	&__cart {
		display: flex;
		align-items: center;

		&__content {
			width: 100%;
		}

		&__label {
			margin-bottom: vc(10);
			font-size: vc(14);
		}

		&__number {
			font-family: $font-secondary;
			font-weight: 400;
			font-size: vc(20);
			letter-spacing: 0.1em;
			text-transform: uppercase;
		}

		&__icon {
			position: relative;
			flex-shrink: 0;

			svg {
				width: vc(50);
				height: vc(50);
			}

			span {
				position: absolute;
				right: vc(7);
				top: vc(4);
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: vc(18);
				height: vc(18);
				font-size: vc(13);
				color: $color-white;
				background: #006bbb;
			}

			@include tablet {
				margin-left: vc(45);
			}

			@include desktop {
				margin-left: 0;
			}
		}
	}

	&__submit {
		margin-top: vc(20);

		.button {
			width: 100%;
		}

		@include tablet {
			margin-top: 0;

			.button {
				width: vc(240);
			}
		}

		@include desktop {
			margin-top: vc(20);

			.button {
				width: 100%;
			}
		}
	}
}
