.direction {
	margin-bottom: vc(60);

	&__slider {
		@include tablet {
			display: none;
		}
	}

	&__list {
		display: none;
		flex-wrap: wrap;
		margin: 0 vc(-15);

		@include tablet {
			display: flex;
		}
	}

	&__col {
		margin-bottom: vc(30);
		padding: 0 vc(15);
		width: 50%;

		&:nth-child(2n) {
			.direction__item {
				background: $color-cerulean;
			}
		}

		@include desktop {
			width: 33.33334%;
		}
	}

	.swiper-slide {
		.direction__item {
			&:last-child {
				background: $color-cerulean;
			}
		}
	}

	&__item {
		display: flex;
		align-items: center;
		border-radius: vc(5);
		padding: vc(15) vc(30);
		color: $color-white;
		background: $color-science-blue;

		&__icon {
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
			border: vc(5) solid $color-link-water;
			border-radius: 50%;
			width: vc(80);
			height: vc(80);
			background: $color-white;

			img {
				width: vc(40);
			}

			@include tablet {
				width: vc(85);
				height: vc(85);
			}
		}

		&__text {
			margin-left: vc(25);
			font-family: $font-secondary;
			font-weight: 300;
			font-size: vc(16);
			letter-spacing: 0.05em;
			text-transform: uppercase;

			@include tablet {
				font-size: vc(20);
			}
		}

		& + & {
			margin-top: vc(20);
		}

		@include tablet {
			padding: vc(20) vc(30);
		}

		@include desktop {
			padding: vc(18) vc(45);
		}
	}

	@include tablet {
		margin-bottom: vc(70);
	}
}
