.news {
	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vc(-15) vc(-30);
	}

	&__col {
		margin-bottom: vc(30);
		padding: 0 vc(15);
		width: 100%;

		@include tablet {
			width: 50%;
		}

		@include desktop {
			width: 25%;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		height: 100%;

		&__image {
			margin-bottom: vc(15);

			img {
				width: 100%;
				height: vc(228);
				object-fit: cover;
			}

			@include tablet {
				margin-bottom: vc(20);

				img {
					height: vc(190);
				}
			}
		}

		&__title {
			margin: 0 0 vc(15);
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			display: -webkit-box;
			overflow: hidden;
			font-size: vc(16);
			text-overflow: ellipsis;

			@include tablet {
				margin-bottom: vc(10);
			}
		}

		&__date {
			font-size: vc(16);
			color: #9f9f9f;
		}
	}
}
