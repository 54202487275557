.clients {
	margin-bottom: vc(60);

	&__slider {
		position: relative;

		.swiper-container {
			overflow: hidden;
		}
	}

	&__image {
		img {
			width: vc(120);
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
