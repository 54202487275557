.file {
	margin-bottom: vc(15);

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__text {
		margin-right: vc(20);
		width: 100%;
		max-width: vc(400);
		font-size: vc(12);

		@include tablet {
			font-size: vc(14);
		}
	}

	&__label {
		flex-shrink: 0;
		margin-left: auto;

		button {
			display: flex;
			align-items: center;
			outline: none;
			border: none;
			padding: 0;
			font-size: vc(14);
			line-height: 1.5;
			color: #006bbb;
			background: none;

			svg {
				flex-shrink: 0;
				margin-right: vc(5);
				width: vc(22);
				height: vc(22);
			}

			&:hover {
				color: #0078d1;
			}
		}
	}

	&__list {
		margin-top: vc(20);
	}

	&-item {
		display: flex;
		align-items: center;
		font-size: vc(14);

		svg {
			flex-shrink: 0;
			margin-right: vc(8);
			width: vc(14);
			height: vc(16);
		}

		&__remove {
			position: relative;
			flex-shrink: 0;
			margin-left: vc(5);
			width: vc(16);
			height: vc(16);

			&::before,
			&::after {
				content: "";
				position: absolute;
				left: 19%;
				top: 50%;
				width: 62%;
				height: 1px;
				background: #ff474e;
			}

			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}
		}

		& + & {
			margin-top: vc(10);
		}
	}
}
