.select {
	select {
		outline: none;
		border: none;
		padding: 0 vc(48) 0 vc(20);
		width: 100%;
		height: vc(52);
		font-family: $font-primary;
		font-size: vc(14);
		background: $color-white url("../images/select.svg") right vc(16) center no-repeat;
		appearance: none;
	}

	.choices {
		margin: 0;
		outline: none !important;

		&::after {
			display: none;
		}

		&__inner {
			border: none;
			border-radius: 0;
			padding: 0 !important;
			background: none;
		}

		&__list {
			&--single {
				border: none;
				border-radius: 0;
				padding: vc(17) vc(48) vc(17) vc(20);
				width: 100%;
				height: vc(52);
				font-size: vc(14);
				line-height: vc(17);
				background: $color-white;

				&::after {
					content: "";
					position: absolute;
					right: vc(16);
					top: 50%;
					margin-top: vc(-8);
					width: vc(16);
					height: vc(16);
					background: url("../images/select.svg") 50% 50% no-repeat;
					background-size: contain;
				}

				.choices__item--selectable {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			&--dropdown {
				border: none;
				z-index: 99;

				.choices__placeholder {
					display: none;
				}

				.choices__item {
					margin-top: -1px;
					border: solid 1px #e0e0e0;
					padding: vc(17) vc(19);
					font-size: vc(14);
					line-height: vc(17);

					&:first-child {
						margin-top: 0;
					}

					&.is-selected,
					&.is-highlighted {
						color: #006bbb;
						background: none;
					}
				}
			}
		}

		.just-validate-error-field ~ .choices__list--single {
			border-color: #e32112;
		}

		&.is-open .choices__list {
			&--single {
				&::after {
					transform: rotate(180deg);
				}
			}
		}
	}
}
