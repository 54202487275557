.radio {
	margin-bottom: vc(10);
	font-size: vc(16);

	label {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		padding-left: vc(34);
		cursor: pointer;

		input {
			position: absolute;
			left: vc(7);
			top: 50%;
			outline: none;
			border: none;
			padding: 0;
			width: 1px;
			height: 1px;
			background: none;
			opacity: 0;

			&:checked ~ i {
				&::before {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		i {
			position: absolute;
			left: 0;
			top: 50%;
			border: 1px solid #ff474e;
			border-radius: 50%;
			width: vc(24);
			height: vc(24);
			background: $color-white;
			transform: translateY(-50%);

			&::before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				border-radius: 50%;
				width: vc(10);
				height: vc(10);
				background: #ff474e;
				opacity: 0;
				visibility: hidden;
				transform: translate(-50%, -50%);
				transition: $duration-normal;
			}
		}
	}

	a {
		text-decoration: underline;
		color: #2f2c7c;

		&:hover {
			color: #0078d1;
		}
	}
}
