.events {
	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vc(-15) vc(-30);
	}

	&__col {
		margin-bottom: vc(30);
		padding: 0 vc(15);
		width: 100%;

		@include tablet {
			width: 50%;
		}

		@include desktop {
			width: 33.33334%;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		height: 100%;

		&__image {
			margin-bottom: vc(15);

			img {
				width: 100%;
				height: vc(228);
				object-fit: cover;
			}

			@include tablet {
				margin-bottom: vc(20);
	
				img {
					height: vc(260);
				}
			}
		}

		&__date {
			margin-bottom: vc(15);
			font-weight: 700;

			@include tablet {
				margin-bottom: vc(10);
			}
		}

		&__text {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			display: -webkit-box;
			overflow: hidden;
			font-size: vc(14);
			text-overflow: ellipsis;

			@include tablet {
				font-size: vc(16);
			}
		}
	}
}
