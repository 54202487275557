.dealers {
	margin-bottom: vc(60);

	&__map {
		display: none;
		margin-bottom: vc(20);
		height: vc(320);

		@include tablet {
			display: block;
		}
	}

	&__list {
		@include tablet {
			.swiper-wrapper {
				flex-wrap: wrap;
				width: auto;
				margin: 0 vc(-15) vc(-30);
			}
	
			.swiper-slide {
				margin-bottom: vc(30);
				padding: 0 vc(15);
				width: 50%;
				height: auto;
				box-sizing: border-box;
			}
		}

		@include desktop {
			.swiper-slide {
				width: 33.33334%;
			}
		}
	}

	&__item {
		border-left: solid 1px #e0e0e0;
		padding-left: vc(10);
		height: 100%;

		&__title {
			margin-bottom: vc(15);
			font-size: vc(18);

			@include tablet {
				font-size: vc(20);
			}
		}

		&__phone {
			margin-bottom: vc(10);
			font-size: vc(16);
		}

		&__email {
			margin-bottom: vc(15);
			font-size: vc(16);
		}

		&__text {
			font-size: vc(14);
			color: #4A4A4A;
		}

		@include tablet {
			padding-left: vc(20);
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
