.benefits {
	counter-reset: benefits;
	margin-bottom: vc(60);

	&__row {
		@include desktop {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__col {
		& + & {
			margin-top: vc(5);
		}

		@include tablet {
			margin: 0 auto;
			max-width: vc(555);
	
			&:nth-child(2n) {
				.benefits__item {
					flex-direction: row-reverse;
				}
			}

			& + & {
				margin-top: 0;
			}
		}

		@include desktop {
			margin: 0;
			width: 25%;
			max-width: none;

			&:nth-child(2n) {
				.benefits__item {
					flex-direction: column-reverse;
				}
			}
		}
	}

	&__item {
		counter-increment: benefits;

		&__content {
			position: relative;
			display: flex;
			align-items: flex-end;
			padding: vc(30);
			height: vc(150);
			background: $color-white;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				border-top: solid vc(30) #eff7ff;
				border-right: solid vc(30) transparent;
			}

			&::after {
				content: counter(benefits);
				position: absolute;
				right: vc(20);
				top: vc(15);
				font-size: vc(16);
				letter-spacing: 0.02em;
				color: #9F9F9F;
			}

			@include tablet {
				width: 50%;
			}

			@include desktop {
				width: auto;
			}
		}
		
		&__text {
			max-width: vc(150);
			font-size: vc(16);
		}

		&__image {
			display: none;
			align-items: center;
			justify-content: center;
			width: 50%;
			height: vc(150);

			img {
				width: vc(40);
			}

			@include tablet {
				display: flex;
			}

			@include desktop {
				width: auto;
			}
		}

		@include tablet {
			display: flex;
		}

		@include desktop {
			flex-direction: column;
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
