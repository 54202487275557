.product {
	margin-bottom: vc(60);

	&__gallery {
		margin-bottom: vc(40);

		&__carousel {
			position: relative;
			margin: 0 vc(-15);

			&__image {
				background: $color-white;

				img {
					width: 100%;
					height: vc(195);
					object-fit: contain;
				}

				@include tablet {
					img {
						height: vc(420);
					}
				}
			}

			.arrow-prev {
				left: vc(20);
			}

			.arrow-next {
				right: vc(20);
			}

			@include tablet {
				margin: 0;
			}
		}

		&__thumbs {
			display: none;
			margin-top: vc(20);

			&__image {
				position: relative;
				background: $color-white;
				cursor: pointer;

				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: #006bbb;
					opacity: 0;
					transition: $duration-normal;
				}

				img {
					width: 100%;
					height: vc(68);
					object-fit: contain;
				}

				.swiper-slide-thumb-active & {
					&::before {
						opacity: 0.15;
					}
				}
			}

			@include tablet {
				display: block;
			}
		}

		@include tablet {
			margin-bottom: vc(60);
		}

		@include desktop {
			margin-bottom: 0;
		}
	}

	&__card {
		margin-bottom: vc(60);

		&__left {
			@include desktop {
				flex-shrink: 0;
				margin-right: vc(30);
				width: vc(670);
			}
		}

		&__right {
			width: 100%;
		}

		&__info {
			margin-bottom: vc(20);
			font-size: vc(15);

			li {
				+ li {
					margin-top: vc(20);
				}
			}

			&__label {
				margin-right: vc(15);
				color: #4a4a4a;
			}

			a {
				text-transform: uppercase;
				color: #006bbb;
			}

			@include tablet {
				display: flex;
				justify-content: space-between;

				li {
					+ li {
						margin-top: 0;
					}
				}
			}
		}

		&__description {
			margin-bottom: vc(40);
			font-size: vc(14);
		}

		&__cart {
			margin-bottom: vc(20);

			&__request {
				margin-bottom: vc(20);

				a {
					display: inline-block;
					vertical-align: middle;
					border: 1px solid #006bbb;
					border-radius: vc(5);
					padding: vc(15) vc(39);
					width: 100%;
					font-family: $font-secondary;
					font-size: vc(14);
					line-height: 1.5;
					letter-spacing: 0.1em;
					text-align: center;
					text-transform: uppercase;
					color: #006bbb;
					background: $color-white;

					&:hover {
						border-color: #0078d1;
						color: #0078d1;
					}

					&:active {
						border-color: #005ea5;
						color: #005ea5;
					}
				}

				@include tablet {
					margin-bottom: 0;

					a {
						width: auto;
					}
				}
			}

			&__price {
				margin-bottom: vc(20);
				font-family: $font-secondary;
				font-size: vc(20);
				letter-spacing: 0.1em;
				text-transform: uppercase;

				@include tablet {
					margin-bottom: 0;
				}

				@include desktop {
					width: vc(200);
					text-align: center;
				}
			}

			&__add {
				.button {
					width: 100%;
				}

				@include tablet {
					margin-left: auto;

					.button {
						width: auto;
					}
				}
			}

			@include tablet {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: vc(26);
			}
		}

		&__demo {
			flex-shrink: 0;
			margin-bottom: vc(40);

			a {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				border-radius: vc(20);
				padding: vc(8) vc(16) vc(8) vc(46);
				font-size: vc(14);
				color: #4a4a4a;
				background: #e0e0e0;

				svg {
					position: absolute;
					left: 0;
					top: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					width: vc(42);
					height: vc(42);
					transform: translateY(-50%);
				}
			}

			&--desktop {
				display: none;
			}

			@include tablet {
				margin-bottom: 0;

				&--mobile {
					display: none;
				}

				&--desktop {
					display: block;
				}
			}
		}

		&__options {
			margin-bottom: vc(40);

			&__list {
				display: flex;
				margin-left: auto;
				font-size: vc(14);

				li {
					+ li {
						margin-left: vc(30);
					}

					a {
						display: flex;
						align-items: center;
						color: #006bbb;

						svg {
							flex-shrink: 0;
							margin-right: vc(5);
							width: vc(20);
							height: vc(20);
						}

						&:hover {
							color: #0078d1;
						}

						&:active {
							color: #005ea5;
						}
					}
				}
			}

			@include tablet {
				display: flex;
				align-items: center;
				margin-bottom: vc(30);
			}
		}

		&__payment {
			margin-top: vc(40);
			padding: vc(30) vc(70) vc(30) vc(30);
			max-width: vc(470);
			font-size: vc(14);
			background: $color-white;

			&__col {
				& + & {
					margin-top: vc(30);
				}

				@include tablet {
					padding: 0 vc(15);
					width: 50%;

					& + & {
						margin-top: 0;
					}
				}
			}

			&__title {
				margin-bottom: vc(15);
			}

			ul {
				li {
					position: relative;
					padding-left: vc(22);

					&::before {
						content: "";
						position: absolute;
						left: vc(8);
						top: vc(9);
						border-radius: 50%;
						width: vc(5);
						height: vc(5);
						background: currentColor;
					}

					+ li {
						margin-top: vc(5);
					}

					a {
						color: inherit;
					}
				}
			}

			@include tablet {
				display: flex;
				margin-top: vc(30);
				padding: vc(30) vc(15);
			}

			@include desktop {
				max-width: none;
			}
		}

		&__share {
			position: relative;

			&__list {
				position: absolute;
				left: 0;
				top: 100%;
				z-index: 5;
				margin-top: vc(10);
				box-shadow: 0 vc(4) vc(10) rgba($color-black, 0.15);
				white-space: nowrap;
				background: $color-white;
				opacity: 0;
				visibility: hidden;
				transition: $duration-normal;

				li {
					margin: 0;

					+ li {
						margin: 0;
					}

					a {
						padding: vc(12) vc(15);
					}
				}

				.is-show-list & {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		@include desktop {
			display: flex;
		}
	}

	&__tabs {
		margin-bottom: vc(40);
		padding-top: 1px;

		&__list {
			display: none;
			flex-wrap: wrap;
			margin-bottom: vc(40);
			padding: 1px 0 0 1px;

			li {
				margin-top: -1px;
				width: 33.3334%;

				a {
					display: block;
					margin-left: -1px;
					border: 1px solid #e0e0e0;
					padding: vc(13) vc(10);
					height: 100%;
					font-size: vc(14);
					text-align: center;

					&.is-active {
						border: 1px solid #006bbb;
						font-weight: 700;
						color: $color-white;
						background: #006bbb;
					}
				}

				@include desktop {
					flex: 1;
					width: auto;
				}
			}

			@include tablet {
				display: flex;
			}

			@include desktop {
				flex-wrap: nowrap;
			}
		}

		&__open {
			position: relative;
			display: block;
			margin-top: -1px;
			border: 1px solid #e0e0e0;
			padding: vc(15) vc(20);
			width: 100%;
			font-size: vc(14);

			svg {
				position: absolute;
				right: vc(20);
				top: 50%;
				margin-top: vc(-8);
				width: vc(16);
				height: vc(16);
				transform: rotate(180deg);
				transition: $duration-normal;
			}

			&.is-active {
				svg {
					transform: rotate(0);
				}
			}

			@include tablet {
				display: none;
			}
		}

		&__item {
			display: none;
			padding: vc(20) 0;

			.table {
				table {
					width: vc(540);
				}

				@include tablet {
					table {
						width: 100%;
					}
				}
			}

			&.is-active {
				display: block;
				animation: showTab $duration-normal;
			}

			@include tablet {
				padding: 0;
			}
		}

		&__description {
			@include tablet {
				margin-bottom: vc(40);
			}
		}

		.table {
			margin-top: vc(20);
		}

		@include tablet {
			margin-bottom: 0;
			padding-top: 0;
		}

		@keyframes showTab {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}
	}

	&__properties {
		&__title {
			margin: 0 0 vc(20);
			font-family: $font-secondary;
			font-weight: 300;
			font-size: vc(16);
			letter-spacing: 0.05em;
			text-transform: uppercase;

			@include tablet {
				font-size: vc(20);
			}
		}

		&__table {
			table {
				width: 100%;
				font-size: vc(15);

				tbody {
					td {
						padding: vc(10) vc(20);
					}

					tr {
						&:nth-child(2n + 1) {
							td {
								background: $color-white;
							}
						}
					}
				}
			}
		}

		&--desktop {
			display: none;
		}

		@include tablet {
			&--mobile {
				display: none;
			}

			&--desktop {
				display: block;
			}
		}
	}

	&__request {
		position: fixed;
		right: 0;
		top: 50%;
		z-index: 15;
		display: flex;
		align-items: center;
		border: solid 1px currentColor;
		padding: vc(8) vc(34);
		font-size: vc(14);
		color: #4a4a4a;
		background: $color-white;
		transform: rotate(-90deg) translate(50%, -50%);
		transform-origin: 100% 50%;

		svg {
			flex-shrink: 0;
			margin-right: vc(10);
			width: vc(24);
			height: vc(24);
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
