.manufacturers-list {
	margin-bottom: vc(60);

	&__slider {
		@include tablet {
			display: none;
		}
	}

	&__list {
		columns: 2;
		column-gap: vc(20);
		margin-bottom: vc(-20);

		li {
			margin-bottom: vc(20);
		}

		&--desktop {
			display: none;
		}

		@include tablet {
			columns: 4;
			column-gap: vc(10);
			margin-bottom: vc(-20);

			&--desktop {
				display: block;
			}
		}

		@include desktop {
			columns: 5;
			column-gap: vc(60);
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
