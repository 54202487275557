.files {
	&__slider {
		.files__item {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vc(-15);
	}

	&__col {
		position: relative;
		padding: 0 vc(15);
		width: 100%;

		& + & {
			margin-top: vc(20);

			@include tablet {
				margin-top: 0;
			}

			@include desktop {
				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 1px;
					height: 100%;
					background: $color-alto;
				}
			}
		}

		@include tablet {
			width: 50%;
		}

		@include desktop {
			width: 33.33334%;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		margin-bottom: vc(20);
		font-size: vc(14);

		&__icon {
			margin-right: vc(10);

			svg {
				width: vc(16);
				height: vc(16);
			}
		}

		&__info {
			margin-left: auto;
			color: #9f9f9f;
		}
	}
}
