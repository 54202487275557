.reviews {
	margin-bottom: vc(60);

	&__slider {
		position: relative;

		.swiper-container {
			overflow: hidden;
		}

		.swiper-slide {
			height: auto;
		}
	}

	&__item {
		height: 100%;

		&__image {
			flex-shrink: 0;
			text-align: center;

			img {
				width: vc(230);
			}

			@include tablet {
				margin-right: vc(25);
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			margin-top: vc(35);

			@include tablet {
				margin-top: 0;
				padding: vc(30) 0;
				width: vc(310);
			}

			@include desktop {
				width: auto;
			}
		}

		&__logo {
			margin-bottom: vc(20);
			text-align: center;

			img {
				width: vc(270);
			}

			@include tablet {
				margin-bottom: vc(30);
			}

			@include desktop {
				margin-bottom: vc(50);
				text-align: left;
			}
		}

		&__title {
			margin: 0 0 vc(10);

			@include tablet {
				margin: auto 0 vc(20);
			}
		}

		&__post {
			margin-bottom: vc(10);
			color: $color-tundora;

			@include tablet {
				margin-bottom: vc(20);
			}
		}

		&__text {
			font-size: vc(14);
			line-height: vc(21);
		}

		@include tablet {
			display: flex;
			justify-content: center;
		}

		@include desktop {
			justify-content: normal;
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
