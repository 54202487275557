// stylelint-disable scss/dollar-variable-empty-line-before

// COLORS
$color-black: #000;
$color-white: #fff;
$color-gray: #eee;
$color-lochmara: #0078d1;
$color-tundora: #4a4a4a;
$color-mercury: #e2e2e2;
$color-science-blue: #006bbb;
$color-link-water: #e4eefa;
$color-cerulean: #009ce6;
$color-alto: #e0e0e0;

// FONTS
// stylelint-disable-next-line
$font-primary: "Tahoma", "Arial", "Helvetica Neue", sans-serif;
$font-secondary: "Oswald", sans-serif;

// EASING TRANSITIONS
$duration-normal: 0.4s;
$easing-back-out: cubic-bezier(0.25, 0.74, 0.22, 1.1); // http://cubic-bezier.com/#.25,.74,.22,1.1
$easing-back-in: cubic-bezier(0.47, -0.1, 0.5, -0.06); // http://cubic-bezier.com/#.47,-0.1,.5,-.06
$easing-out: ease-out;
$easing-in: ease-in;

// Settings
$desktop: 1024;
$desktop-xl: 1340;
$laptop: 1440;
$tablet: 768;
$mobile: 320;
$desktop-vh: 750;
