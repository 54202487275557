.filter-mini {
	margin-bottom: vc(40);

	&__item {
		&__label {
			margin-bottom: vc(15);
			font-size: vc(16);
			color: #9f9f9f;

			@include tablet {
				margin: 0 vc(30) 0 0;
			}
		}

		&__button {
			a {
				display: inline-block;
				vertical-align: middle;
				border: 1px solid #006bbb;
				border-radius: vc(5);
				padding: vc(15) vc(11);
				width: 100%;
				font-family: $font-secondary;
				font-size: vc(14);
				line-height: 1.5;
				letter-spacing: 0.1em;
				text-align: center;
				text-transform: uppercase;
				color: #006bbb;
				background: $color-white;

				&:hover {
					border-color: #0078d1;
					color: #0078d1;
				}

				&:active {
					border-color: #005ea5;
					color: #005ea5;
				}
			}

			@include tablet {
				margin-bottom: 0;

				a {
					width: auto;
				}
			}
		}

		& + & {
			margin-top: vc(20);
		}

		@include tablet {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.select {
				width: vc(370);
			}
		}

		@include desktop {
			justify-content: flex-start;

			& + & {
				margin-top: 0;
			}
		}
	}

	&__search {
		&__field {
			display: block;
			outline: none;
			border: none;
			padding: 0 vc(20);
			width: 100%;
			height: vc(52);
			font-size: vc(14);
			background: $color-white;

			&::placeholder {
				color: #9f9f9f;
			}
		}

		&__submit {
			display: block;
			margin-top: vc(20);
			outline: none;
			border: solid 1px #ff474e;
			width: 100%;
			height: vc(52);
			font-family: $font-secondary;
			font-weight: 400;
			font-size: vc(14);
			text-align: center;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #ff474e;
			background: $color-white;

			@include tablet {
				margin-top: 0;
				width: vc(100);
			}
		}

		@include tablet {
			display: flex;
			width: vc(370);
		}
	}

	@include desktop {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
