.bottom {
	$p: &;

	position: relative;
	z-index: 5;
	display: flex;
	margin-bottom: vc(40);
	padding: vc(60) 0 0;
	height: vc(507);
	text-align: center;
	color: $color-white;

	&__background {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background: url("../images/bottom-mobile.jpg") 0 50% no-repeat;
		background-size: cover;

		@include desktop {
			background-image: url("../images/bottom.jpg");
			background-position: 50% 50%;
		}
	}

	&__container {
		@include desktop {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&__content {
		width: 100%;
	}

	&__title {
		margin: 0 0 vc(20);
		font-family: $font-secondary;
		font-weight: 300;
		font-size: vc(26);
		letter-spacing: 0.1em;
		text-transform: uppercase;

		@include tablet {
			margin: 0 0 vc(10);
			padding: 0 vc(70);
			font-size: vc(28);
		}

		@include desktop {
			padding: 0;
		}
	}

	&__text {
	}

	&__sub-text {
		margin-top: vc(20);
		padding: 0 vc(20);
		font-size: vc(14);
		opacity: 0.8;

		@include tablet {
			margin-top: vc(10);
			padding: 0;
		}

		@include desktop {
			margin-top: vc(20);
		}
	}

	&__line {
		position: relative;
		margin: vc(24) 0 vc(44);
		height: 1px;
		background: #ff474e;

		&::before {
			content: "";
			position: absolute;
			right: 0;
			top: 50%;
			border-radius: 50%;
			width: vc(9);
			height: vc(9);
			background: #ff474e;
			transform: translateY(-50%);
		}

		@include tablet {
			margin: vc(40) 0;
		}

		@include desktop {
			margin: vc(40) vc(-257) vc(40) 0;
		}
	}

	&__phone {
		font-family: $font-secondary;
		font-size: vc(20);

		li {
			+ li {
				position: relative;
				margin-top: vc(10);
			}
		}

		@include tablet {
			display: flex;
			justify-content: center;
			font-size: vc(26);

			li {
				+ li {
					position: relative;
					margin: 0 0 0 vc(60);

					&::before {
						content: "";
						position: absolute;
						left: vc(-30);
						top: 50%;
						width: 1px;
						height: vc(28);
						background: $color-white;
						opacity: 0.3;
						transform: translateY(-50%);
					}
				}
			}
		}
	}

	&__button {
		margin-top: vc(40);

		a {
			width: vc(270);
		}
	}

	&__image {
		display: none;
		flex-shrink: 0;
		margin-left: vc(250);

		img {
			border: solid vc(3) $color-white;
			border-radius: 50%;
			width: vc(476);
			height: vc(476);
			object-fit: cover;
			pointer-events: none;
		}

		@include desktop {
			display: block;
		}
	}

	&__subscribe {
		margin: 0 auto;
		text-align: center;

		&__image {
			display: none;
			margin-bottom: vc(40);

			img {
				width: vc(330);
			}

			@include desktop {
				display: block;
			}
		}

		.form__submit {
			padding: 0;
			width: auto;
		}

		@include tablet {
			width: vc(570);
		}

		@include desktop {
			flex-shrink: 0;
			margin: 0 0 0 vc(130);
		}
	}

	&--subscribe {
		height: vc(570);
	}

	@include tablet {
		align-items: center;
		padding: vc(24) 0;
		height: vc(524);

		&--subscribe {
			#{$p}__title {
				padding: 0;
			}

			#{$p}__text {
				margin-right: auto;
				margin-left: auto;
				max-width: vc(410);
			}
		}
	}

	@include desktop {
		height: auto;
		//text-align: left;

		&--subscribe {
			padding: vc(100) 0;

			#{$p}__background {
				background-image: url("../images/bottom-subscribe.jpg");
			}

			#{$p}__line {
				margin-right: 0;
			}

			#{$p}__text {
				margin-right: 0;
				margin-left: 0;
				max-width: none;
			}
		}
	}
}

.bottom--left {
	@include desktop {
		text-align: left;
	}
}
