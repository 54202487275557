.modal {
	padding: vc(60) vc(14);
	width: 100%;
	background: #eff7ff;

	&__title {
		margin-bottom: vc(30);
		font-family: $font-secondary;
		font-weight: 300;
		font-size: vc(20);
		letter-spacing: 0.1em;
		text-align: center;
		text-transform: uppercase;

		@include tablet {
			font-size: vc(30);
		}
	}

	.input__field {
		input,
		textarea {
			@include devices {
				font-size: vc(14);
			}
		}
	}

	.form__footer {
		display: block;
		margin-top: vc(15);
	}

	.form__submit {
		padding: 0;
		width: 100%;

		.button {
			@include mobile {
				padding: vc(5) vc(15);
				height: vc(52);
			}
		}
	}

	.form__agree {
		margin-top: vc(20);
		padding: 0;
		width: 100%;
		text-align: center;
	}

	.carousel__button {
		&.is-close {
			top: 0;
			border-radius: 0;
			width: vc(40);
			height: vc(40);
			background: $color-science-blue;

			&::before,
			&::after {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				width: vc(20);
				height: 1px;
				background: $color-white;
				transform: translate3d(-50%, -50%, 0) rotate(45deg);
			}

			&::after {
				transform: translate3d(-50%, -50%, 0) rotate(-45deg);
			}

			svg {
				display: none;
			}
		}
	}

	&--success {
		.modal__text {
			text-align: center;
		}
	}

	@include tablet {
		padding: vc(60);
		width: vc(690);

		&--success {
			width: vc(460);
		}
	}
}

.fancybox__backdrop {
	background: rgba(0, 107, 187, 0.5);
}

.fancybox__slide {
	@include devices {
		padding: 0;
	}
}
