.show-more {
	margin-top: vc(40);

	&__title {
		margin: 0 0 vc(10);
		font-family: $font-secondary;
		font-weight: 300;
		font-size: vc(16);
		letter-spacing: 0.05em;
		text-transform: uppercase;

		@include tablet {
			margin: 0 0 vc(20);
			font-size: vc(20);
		}
	}

	&__content {
		&-wrap {
			position: relative;
			overflow: hidden;
			max-height: vc(357);

			.is-hide-content & {
				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(180deg, rgba(239, 247, 255, 0) 0%, #eff7ff 100%);
				}
			}

			.is-show-content & {
				max-height: none !important;

				&::before {
					display: none;
				}
			}

			@include tablet {
				max-height: vc(161);
			}

			@include desktop {
				max-height: vc(138);
			}
		}
	}

	&__text {
		font-size: vc(15);
	}

	&__more {
		margin-top: vc(20);
		font-family: $font-secondary;
		font-size: vc(13);
		letter-spacing: 0.1em;
		text-align: right;
		text-transform: uppercase;
		color: #2f2c7c;

		a {
			text-decoration: underline;
		}
	}

	&:first-child {
		margin-top: 0;
	}

	@include tablet {
		margin-top: vc(60);
	}
}
