.about {
	margin-bottom: vc(60);

	&__content {
		margin-bottom: vc(30);

		@include tablet {
			margin-bottom: vc(40);
			max-width: vc(906);
		}
	}

	&__row {
		@include tablet {
			display: flex;
			justify-content: space-between;
		}
	}

	&__video {
		position: relative;
		display: block;
		overflow: hidden;
		border-radius: vc(10);
		width: 100%;
		height: vc(252);

		&__image {
			border-radius: vc(10);
			height: 100%;
			background: 0 50% no-repeat;
			background-size: cover;
		}

		&__play {
			position: absolute;
			left: 50%;
			top: 50%;
			width: vc(150);
			height: vc(158);
			transform: translate(-50%, -50%);

			@include tablet {
				width: vc(205);
				height: vc(216);
			}
		}

		@include tablet {
			height: auto;
		}
	}

	&__list {
		flex-shrink: 0;
		margin-top: vc(20);
		border: 1px solid $color-mercury;
		border-radius: vc(5);
		width: 100%;
		background: $color-white;

		&__item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: vc(19) vc(15);
			font-size: vc(13);
			line-height: vc(16);

			svg {
				margin-left: vc(7);
				width: vc(26);
				height: vc(26);
			}

			& + & {
				border-top: 1px solid $color-mercury;
			}
		}

		@include tablet {
			margin-top: 0;
			margin-left: vc(38);
			width: vc(170);
		}

		@include desktop {
			margin-left: vc(130);
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
