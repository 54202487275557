.manufacturers {
	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vc(-15) vc(-20);

		@include tablet {
			margin-bottom: vc(-30);
		}
	}

	&__col {
		margin-bottom: vc(50);
		padding: 0 vc(15);
		width: 100%;

		@include tablet {
			margin-bottom: vc(30);
			width: 50%;
		}

		@include desktop {
			width: 25%;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		height: 100%;

		&__name {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			margin-bottom: vc(20);
			padding: vc(20);
			font-family: $font-secondary;
			font-weight: 400;
			font-size: vc(16);
			text-align: center;
			letter-spacing: 0.06em;
			text-transform: uppercase;
			color: #006bbb;
			background: $color-white;

			&--blue {
				background: #e4eefa;
			}

			@include tablet {
				padding: vc(20) vc(50);
				height: vc(94);
				font-size: vc(18);
			}

			@include desktop {
				padding: vc(20);
			}
		}

		&__text {
			flex: 1;
			border-left: solid 1px #e0e0e0;
			padding-left: vc(15);
			font-size: vc(14);
		}
	}
}
