.is-hidden {
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}

.is-hide {
	// stylelint-disable-next-line declaration-no-important
	opacity: 0;
}

.for-desktop {
	@include devices {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.for-tablet {
	@include desktop {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}

	@include mobile {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.for-mobile {
	@include desktop {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}

	@include tablet {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.for-not-mobile {
	@include mobile {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.for-devices {
	@include desktop {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.clearfix {
	overflow: auto;

	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

.visually-hidden {
	position: absolute;
	overflow: hidden;
	margin: -1px;
	width: 1px;
	height: 1px;
	clip: rect(0 0 0 0);
}

/* stylelint-disable */
.dont-break-out {
	// Технически это одно и то же, но используйте и то, и другое
	overflow-wrap: break-word;
	word-wrap: break-word;
	// Это самое опасное в WebKit, так как оно ломает вещи везде, где бы они ни были
	word-break: break-all;
	// Вместо этого используйте этот нестандартный:
	word-break: break-word;
	// Добавляет дефис там, где слово ломается, если поддерживается
	hyphens: auto;
}
/* stylelint-enable */

.container {
	margin: 0 auto;
	padding: 0 vc(15);
	width: 100%;

	@include tablet {
		max-width: vc(720);
	}

	@include desktop {
		max-width: vc(1200);
	}
}

.content {
	padding: vc(20) 0 vc(60);

	@include tablet {
		padding: vc(40) 0 vc(100);
		min-height: calc(100vh - #{vc(702)});
	}

	@include desktop {
		min-height: calc(100vh - #{vc(616)});
	}
}

.title {
	position: relative;
	margin: 0 0 vc(44);
	padding-bottom: vc(15);

	&__text {
		margin: 0;
		font-family: $font-secondary;
		font-weight: 300;
		font-size: vc(26);
		letter-spacing: 0.1em;
		text-transform: uppercase;

		&--small {
			font-size: vc(16);
		}

		@include tablet {
			font-size: vc(30);

			&--small {
				font-size: vc(24);
			}
		}
	}

	&__decor {
		position: absolute;
		left: 0;
		bottom: 0;
		width: vc(200);
		height: 1px;
		background: #9f9f9f;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			border-radius: 50%;
			width: vc(9);
			height: vc(9);
			background: #9f9f9f;
			transform: translateY(-50%);
		}

		@include tablet {
			position: relative;
			left: auto;
			bottom: auto;
			flex-shrink: 0;
			margin-left: vc(59);
		}

		@include desktop {
			flex: 1;
			margin-left: vc(32);
			width: auto;
			max-width: vc(700);
		}
	}

	@include tablet {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 vc(40);
		padding: 0;
	}
}

.subtitle {
	margin-bottom: vc(20);
	font-family: $font-secondary;
	font-weight: 300;
	font-size: vc(16);
	letter-spacing: 0.05em;
	text-transform: uppercase;

	@include tablet {
		font-size: vc(20);
	}
}

.list-text {
	ul {
		li {
			position: relative;
			padding-left: vc(20);

			&::before {
				content: "";
				position: absolute;
				left: vc(6);
				top: vc(9);
				border-radius: 50%;
				width: vc(5);
				height: vc(5);
				background: $color-black;
			}

			+ li {
				margin-top: vc(10);
			}
		}
	}
}

.text-links {
	a {
		color: $color-science-blue;
		text-decoration: underline;

		&:hover {
			color: #0078d1;
		}
	}
}

.link-all {
	margin-top: vc(40);
	font-size: vc(13);
	text-align: center;

	a {
		display: inline-block;
		vertical-align: middle;
		border-bottom: solid 1px currentColor;
		padding-bottom: vc(5);
		min-width: vc(270);
		text-align: center;
		text-transform: uppercase;
		color: #ff474e;

		&:hover {
			color: #ff5d63;
		}

		&:active {
			color: #ef3a41;
		}
	}

	@include tablet {
		font-size: vc(14);
		text-align: right;
	}

	@include desktop {
		font-size: vc(13);
	}
}

.link-fake {
	border-bottom: 1px dashed #006bbb;
	color: #006bbb;
}

.button {
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #ff474e;
	border-radius: vc(5);
	padding: vc(15) vc(25);
	font-family: $font-secondary;
	font-weight: 600;
	font-size: vc(14);
	line-height: 1.5;
	letter-spacing: 0.1em;
	text-align: center;
	text-transform: uppercase;
	color: $color-white;
	background: #ff474e;

	svg {
		display: inline-block;
		vertical-align: middle;
		margin-top: vc(-4);
		width: vc(16);
		height: vc(16);

		&:first-child {
			margin-right: vc(10);
		}

		&:last-child {
			margin-left: vc(10);
		}
	}

	&:hover {
		border-color: #ff5d63;
		color: $color-white;
		background: #ff5d63;
	}

	&:active {
		border-color: #ef3a41;
		color: $color-white;
		background: #ef3a41;
	}
}

.arrow-prev,
.arrow-next {
	position: absolute;
	left: vc(-39);
	top: 50%;
	z-index: 5;
	display: none;
	border: none;
	padding: 0;
	width: vc(35);
	height: vc(35);
	background: none;
	transform: translateY(-50%);

	&::before {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: vc(7);
		border-bottom: 1px solid #9f9f9f;
		border-left: 1px solid #9f9f9f;
		width: 70%;
		height: 70%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&.swiper-button-disabled {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
	}

	@include tablet {
		display: block;
	}

	@include desktop {
		left: vc(-70);
	}
}

.arrow-next {
	left: auto;
	right: vc(-39);

	&::before {
		margin-left: vc(-7);
		transform: translate(-50%, -50%) rotate(-135deg);
	}

	@include desktop {
		right: vc(-70);
	}
}

.pager {
	counter-reset: newsPager;
	display: flex;
	justify-content: flex-start;
	margin-top: 0;
	font-family: $font-secondary;
	font-weight: 300;
	color: #9f9f9f;

	.swiper-pagination-bullet {
		position: relative;
		display: block;
		margin: 0;
		border-radius: 0;
		padding-bottom: vc(18);
		width: vc(50);
		height: auto;
		font-size: vc(14);
		background: none;
		opacity: 1;
		transition: $duration-normal;
		cursor: pointer;

		&::before {
			counter-increment: newsPager;
			content: counter(newsPager);
		}

		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			border-style: solid;
			border-width: 0 1px 1px;
			border-color: currentColor;
			width: 100%;
			height: vc(8);
		}

		+ .swiper-pagination-bullet {
			margin-left: -1px;
		}

		&:hover {
			color: #006bbb;

			&::after {
				z-index: 2;
			}
		}

		&.swiper-pagination-bullet-active {
			color: #006bbb;
			background: none;

			&::after {
				z-index: 3;
			}
		}

		@include tablet {
			width: vc(66);
		}
	}
}

.pager-dots {
	margin-top: vc(30);
	text-align: center;

	.swiper-pagination-bullet {
		display: inline-block;
		vertical-align: middle;
		margin: 0 vc(7);
		border-radius: 50%;
		width: vc(9);
		height: vc(9);
		background: #006bbb;
		opacity: 0.1;
		transition: $duration-normal;

		&.swiper-pagination-bullet-active {
			opacity: 1;
		}
	}
}
