.order {
	&__container {
		@include desktop {
			display: flex;
		}
	}

	&__content {
		width: 100%;
	}

	&__side {
		margin-top: vc(40);

		&__content {
			border-radius: vc(5);
			padding: vc(30) vc(20);
			background: $color-white;

			@include tablet {
				padding: vc(30);
			}

			@include desktop {
				position: sticky;
				top: vc(30);
				margin-bottom: vc(15);
			}
		}

		&__item {
			margin-bottom: vc(20);
			border-bottom: solid 1px #e0e0e0;
			padding-bottom: vc(20);

			&__title {
				margin-bottom: vc(5);
				font-size: vc(16);
				text-transform: uppercase;
			}

			&__brand {
				margin-bottom: vc(10);
				font-size: vc(16);
				color: #4a4a4a;
			}

			&__footer {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: vc(14);
			}

			&__count {
				color: #9f9f9f;
			}

			&__price {
			}
		}

		&__footer {
			margin-top: vc(20);

			&__top {
				@include tablet {
					display: flex;
					justify-content: space-between;
				}

				@include desktop {
					display: block;
				}
			}

			@include tablet {
				margin: vc(20) vc(-10) 0;
			}

			@include desktop {
				margin: vc(20) 0 0;
			}
		}

		@include desktop {
			flex-shrink: 0;
			margin: 0 0 0 vc(100);
			width: vc(300);
		}
	}

	&__block {
		margin-bottom: vc(40);

		&:last-child {
			margin-bottom: 0;
		}

		@include tablet {
			margin-bottom: vc(30);
		}
	}

	&__head {
		margin-bottom: vc(20);
		padding: vc(20);
		font-family: $font-secondary;
		font-weight: 300;
		font-size: vc(16);
		letter-spacing: 0.05em;
		text-transform: uppercase;
		background: #e4eefa;

		@include tablet {
			font-size: vc(20);
		}
	}

	&__delivery {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: vc(20);

		.radio {
			margin: 0 vc(27) 0 0;
		}
	}

	&__total {
		&__label {
			margin-bottom: vc(10);
			font-size: vc(14);
		}

		&__number {
			font-family: $font-secondary;
			font-size: vc(20);
			letter-spacing: 0.1em;
			text-transform: uppercase;
		}

		@include desktop {
			display: flex;
			justify-content: center;
		}
	}

	&__submit {
		margin-top: vc(20);

		.button {
			width: 100%;
		}

		@include tablet {
			margin-top: 0;

			.button {
				width: vc(240);
			}
		}

		@include desktop {
			margin-top: vc(20);

			.button {
				width: 100%;
			}
		}
	}

	&__agree {
		margin-top: vc(20);

		.checkbox {
			margin: 0;
			font-size: vc(12);
			line-height: 1.5;
		}

		@include tablet {
			margin: vc(20) 0 0 auto;
			width: vc(260);
		}

		@include desktop {
			margin: vc(20) vc(-12) 0;
			width: auto;
		}
	}

	.form {
		&__row {
			@include tablet {
				display: flex;
				flex-wrap: wrap;
				margin: 0 vc(-15);
			}
		}

		&__col {
			@include tablet {
				padding: 0 vc(15);
				width: 50%;
			}
		}
	}

	.input__field {
		@include tablet {
			textarea {
				height: vc(160);
			}
		}
	}

	.file {
		&__label {
			margin-left: 0;
		}
	}
}
