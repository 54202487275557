.table {
	overflow: auto;
	margin: 0 vc(-15);
	padding: 0 vc(15) vc(30);

	table {
		border-collapse: collapse;
		min-width: 100%;
		font-size: vc(14);

		thead {
			th {
				border: 1px solid #e0e0e0;
				padding: vc(10);
				font-weight: 400;
				text-align: center;
				color: $color-white;
				background: $color-science-blue;

				@include tablet {
					padding: vc(15);
				}
			}
		}

		tbody {
			th {
				border: 1px solid #e0e0e0;
				padding: vc(10);
				font-weight: 400;
				text-align: left;
				color: #006bbb;
				background: #e4eefa;

				&.is-blue {
					color: $color-white;
					background: #006bbb;
				}
			}

			td {
				border: 1px solid #e0e0e0;
				padding: vc(10);
				background: $color-white;
			}
		}

		@include tablet {
			width: 100%;
			font-size: vc(15);

			tbody {
				th,
				td {
					padding: vc(15);
				}
			}
		}
	}

	&::-webkit-scrollbar {
		width: vc(4);
		height: vc(4);
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		margin-left: vc(15);
		margin-right: vc(15);
		border-radius: 2px;
		background-color: $color-link-water;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 2px;
		background-color: $color-science-blue;
		opacity: 0;
	}

	@include tablet {
		// overflow: visible;
		margin: 0;
		padding: 0;

		&::-webkit-scrollbar-track {
			margin-left: 0;
			margin-right: 0;
		}
	}
}
