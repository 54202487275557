.advantage {
	margin-bottom: vc(60);

	&__row {
		display: flex;
		flex-wrap: wrap;

		@include tablet {
			margin: 0 vc(-15);
		}
	}

	&__col {
		margin-bottom: vc(30);
		padding: 0 vc(5);
		width: 50%;

		@include tablet {
			padding: 0 vc(15);
		}

		@include desktop {
			width: 33.33334%;
		}
	}

	&__item {
		text-align: center;

		&__icon {
			margin-bottom: vc(15);

			img {
				margin: 0 auto;
				width: vc(40);
			}
		}

		&__text {
			font-family: $font-secondary;
			font-weight: 300;
			letter-spacing: 0.05em;
			text-align: center;
			text-transform: uppercase;
		}
	}

	&__strategy {
		position: relative;
		z-index: 1;
		padding: vc(30);
		background: $color-white;

		&__background {
			position: absolute;
			right: vc(30);
			top: 0;
			z-index: -1;
			display: none;
			width: vc(332);
			height: 100%;
			background: 50% 50% no-repeat;
			background-size: cover;

			@include desktop {
				display: block;
			}
		}

		&__title {
			margin-bottom: vc(20);
			font-family: $font-secondary;
			font-weight: 300;
			font-size: vc(16);
			letter-spacing: 0.05em;
			text-transform: uppercase;

			@include tablet {
				font-size: vc(20);
			}
		}

		&__text {
			font-size: vc(14);
			line-height: 150%;
		}

		@include tablet {
			margin-top: vc(10);
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
