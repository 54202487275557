.updates {
	.title__decor {
		max-width: vc(240);
	}

	&__news {
		&__slider {
			margin-bottom: vc(40);

			@include tablet {
				display: none;
			}
		}

		&__list {
			display: none;

			@include tablet {
				display: flex;
				flex-wrap: wrap;
				margin: 0 vc(-15) vc(15);
			}

			@include desktop {
				display: block;
				margin: 0 0 vc(40);
			}
		}

		&__col {
			margin-bottom: vc(25);
			padding: 0 vc(15);
			width: 50%;

			@include desktop {
				margin-bottom: 0;
				padding: 0;
				width: auto;

				& + & {
					margin-top: vc(25);
				}
			}
		}

		&__item {
			display: flex;

			&__image {
				flex-shrink: 0;
				margin-right: vc(30);
				width: vc(100);

				img {
					border-radius: vc(5);
					width: 100%;
					height: vc(78);
					object-fit: cover;
				}

				@include desktop {
					width: vc(170);
				}
			}

			&__content {
				display: flex;
				flex-direction: column;
				width: 100%;
			}

			&__title {
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				display: -webkit-box;
				overflow: hidden;
				margin: 0 0 vc(10);
				font-size: vc(14);
				text-overflow: ellipsis;
			}

			&__date {
				margin-top: auto;
				font-size: vc(14);
				color: #9f9f9f;
			}

			& + & {
				margin-top: vc(20);
			}
		}

		@include desktop {
			display: flex;
			flex-direction: column;
			width: vc(412);
		}
	}

	&__articles {
		margin-top: vc(60);

		&__slider {
			margin-bottom: vc(40);

			@include tablet {
				display: none;
			}
		}

		&__list {
			display: none;
			margin-bottom: vc(40);

			@include tablet {
				display: block;
			}
		}

		&__item {
			&__image {
				margin-bottom: vc(15);
				padding-bottom: 78.6%;
				background: 50% 50% no-repeat;
				background-size: cover;
			}

			&__title {
				margin: 0 0 vc(10);
				text-transform: uppercase;
				color: #084184;

				@include tablet {
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					display: -webkit-box;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			&__author {
				display: flex;
				align-items: center;
				margin-bottom: vc(15);
				font-size: vc(14);

				svg {
					flex-shrink: 0;
					margin-right: vc(10);
					width: vc(20);
					height: vc(20);
				}
			}

			&__text {
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
				display: -webkit-box;
				overflow: hidden;
				font-size: vc(14);
				text-overflow: ellipsis;

				@include tablet {
					-webkit-line-clamp: 2;
				}
			}

			& + & {
				margin-top: vc(40);
			}
		}

		@include desktop {
			position: relative;
			z-index: 5;
			display: flex;
			flex-direction: column;
			margin: 0 vc(46) 0 0;
			width: vc(570);

			&::before {
				content: "";
				position: absolute;
				left: vc(-54);
				right: vc(-46);
				top: vc(-50);
				bottom: vc(-50);
				z-index: -1;
				background: $color-white url("../images/updates-articles.svg") 50% 65% no-repeat;
				background-size: vc(560) auto;
			}
		}
	}

	.link-all {
		margin-top: auto;
	}

	@include desktop {
		display: flex;
		justify-content: space-between;
		padding: vc(50) 0;
	}
}
