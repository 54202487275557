.guarantees {
	&__quote {
		margin-bottom: vc(30);

		@include tablet {
			margin-bottom: vc(40);
		}
	}

	&__text {
		margin-bottom: vc(20);
	}

	&__list {
		margin-bottom: vc(30);

		@include tablet {
			margin-bottom: vc(40);
		}
	}
}
