.filter {
	position: relative;

	&__head {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: vc(15);
		font-family: $font-secondary;
		font-size: vc(15);
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: $color-white;
		background: #006bbb;

		img {
			flex-shrink: 0;
			margin-right: vc(14);
			width: vc(22);
		}

		svg {
			flex-shrink: 0;
			margin-left: vc(14);
			width: vc(16);
			height: vc(16);
			transform: rotate(180deg);
			transition: $duration-normal;
		}

		.is-show-filter & {
			svg {
				transform: rotate(0);
			}
		}
	}

	&__menu {
		margin-bottom: vc(40);

		ul {
			+ ul {
				margin-top: -1px;
			}

			li {
				border: 1px solid #e0e0e0;

				+ li {
					margin-top: -1px;
				}

				a {
					display: block;
					padding: vc(10.5) vc(19);
					font-size: vc(14);
					background: $color-white;

					&.is-active {
						font-weight: 700;
						color: #ff474e;
					}
				}
			}
		}

		@include tablet {
			display: flex;

			ul {
				flex: 1;
	
				+ ul {
					margin-top: 0;
					margin-left: -1px;
				}
			}
		}

		@include desktop {
			display: block;
			margin-bottom: 0;

			ul {
				+ ul {
					margin-top: -1px;
					margin-left: 0;
				}
			}
		}
	}

	&__catalog {
		margin: 0 vc(-15) vc(40);
		padding: vc(25) vc(15);
		text-align: center;
		color: $color-white;
		background: #006bbb;

		&__title {
			margin: 0 0 vc(15);
			width: 100%;
			font-family: $font-secondary;
			font-size: vc(18);
			letter-spacing: 0.06em;
			text-transform: uppercase;

			@include tablet {
				margin: 0;
			}

			@include desktop {
				margin: 0 0 vc(20);
			}
		}

		&__button {
			.button {
				border-color: $color-white;
				width: vc(220);
				color: #006bbb;
				background: $color-white;
			}

			@include tablet {
				flex-shrink: 0;
				margin: 0 0 0 vc(85);
			}

			@include desktop {
				margin-left: 0;

				.button {
					width: 100%;
				}
			}
		}

		@include tablet {
			display: flex;
			align-items: center;
			margin: 0 0 vc(40);
			padding: vc(25) vc(20);
		}

		@include desktop {
			display: block;
			margin-bottom: 0;
			padding: vc(25);
		}
	}

	&__content {
		position: absolute;
		left: 0;
		top: 100%;
		z-index: 10;
		padding: vc(25) vc(35) vc(15);
		width: 100%;
		background: $color-white;
		opacity: 0;
		visibility: hidden;
		transition: $duration-normal;

		.is-show-filter & {
			opacity: 1;
			visibility: visible;
		}

		@include tablet {
			padding: vc(25);
		}

		@include desktop {
			position: static;
			left: auto;
			top: auto;
			width: auto;
			padding: vc(25) vc(25) vc(15);
			opacity: 1;
			visibility: visible;
			transition: none;
		}
	}

	&__block {
		&__head {
			position: relative;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}

		&__title {
			position: relative;
			padding-right: vc(20);
			font-size: vc(15);
			transition: $duration-normal;
			cursor: pointer;

			svg {
				position: absolute;
				right: 0;
				top: 50%;
				display: inline-block;
				vertical-align: middle;
				margin-top: vc(-8);
				margin-left: vc(5);
				width: vc(16);
				height: vc(17);
				transform: rotate(180deg);
				transition: $duration-normal;
			}

			&:hover {
				color: #0078d1;
			}

			.is-show-content & {
				svg {
					margin-top: vc(-6);
					transform: rotate(0);
				}
			}
		}

		&__reset {
			flex-shrink: 0;
			font-size: vc(13);
			color: #006bbb;
		}

		&__content {
			display: none;
			overflow: auto;
			padding-top: vc(15);
			max-height: vc(300);

			.checkbox {
				&:last-child {
					margin-bottom: 0;
				}
			}

			.is-show-content & {
				display: block;
				animation: showFilterContent $duration-normal;
			}

			@keyframes showFilterContent {
				0% {
					opacity: 0;
				}

				100% {
					opacity: 1;
				}
			}
		}

		& + & {
			margin-top: vc(20);
			border-top: solid 1px #e0e0e0;
			padding-top: vc(20);
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		margin-top: vc(20);

		@include desktop {
			justify-content: space-between;
		}
	}

	&__submit {
		margin-right: vc(20);

		button {
			display: inline-block;
			vertical-align: middle;
			border: 1px solid #ff474e;
			border-radius: 0;
			padding: vc(9) vc(18);
			font-family: $font-secondary;
			font-size: vc(13);
			line-height: 1.5;
			letter-spacing: 0.1em;
			text-align: center;
			text-transform: uppercase;
			color: #ff474e;
			background: $color-white;

			&:hover {
				border-color: #ff5d63;
				color: #ff5d63;
			}

			&:active {
				border-color: #ef3a41;
				color: #ef3a41;
			}
		}

		@include desktop {
			margin-right: 0;
		}
	}

	&__reset {
		button {
			display: inline-block;
			vertical-align: middle;
			border: 1px solid #006bbb;
			border-radius: 0;
			padding: vc(9) vc(18);
			font-family: $font-secondary;
			font-size: vc(13);
			line-height: 1.5;
			letter-spacing: 0.1em;
			text-align: center;
			text-transform: uppercase;
			color: #006bbb;
			background: $color-white;

			&:hover {
				border-color: #0078d1;
				color: #0078d1;
			}

			&:active {
				border-color: #005ea5;
				color: #005ea5;
			}
		}
	}

	&__watched {
		margin-top: vc(25);

		&__title {
			margin: 0 0 vc(20);
			font-family: $font-secondary;
			font-size: vc(18);
			letter-spacing: 0.1em;
			text-transform: uppercase;
		}

		&:first-child {
			margin-top: 0;
		}
	}
}
