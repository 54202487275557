.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: vc(30) 0 vc(40);

	&__list {
		display: flex;
		flex-wrap: wrap;
		padding-left: 1px;

		li {
			margin-left: -1px;
		}

		&__item {
			position: relative;
			display: block;
			border: 1px solid currentColor;
			padding: vc(9) vc(15);
			width: vc(45);
			font-size: vc(13);
			text-align: center;
			color: #9f9f9f;

			&:hover,
			&:active {
				z-index: 1;
			}

			&.is-active {
				z-index: 2;
				border-color: #006bbb;
				color: $color-white;
				background: #006bbb;
			}
		}

		&__dots {
			display: block;
			border: 1px solid transparent;
			padding: vc(9) vc(15);
			width: vc(45);
			font-size: vc(13);
			color: #9f9f9f;
		}

		&--desktop {
			display: none;
		}

		@include tablet {
			&--mobile {
				display: none;
			}

			&--desktop {
				display: flex;
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include tablet {
		margin: vc(40) 0 vc(60);
	}
}
