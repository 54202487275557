.educational-center {
	margin-bottom: vc(60);

	&__container {
		@include desktop {
			display: flex;
		}
	}

	&__content {
		width: 100%;
	}

	&__title {
		margin: 0 0 vc(20);
		font-family: $font-secondary;
		font-weight: 300;
		font-size: vc(16);
		letter-spacing: 0.05em;
		text-transform: uppercase;

		@include tablet {
			font-size: vc(20);
		}
	}

	&__text {
		font-size: vc(14);

		&__title {
			margin: vc(20) 0 vc(10);
			font-weight: 700;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	&__areas {
		margin: vc(20) vc(-15) 0;
		border-radius: vc(5);
		background: $color-white;

		&__image {
			img {
				border: 2px solid $color-white;
				border-radius: vc(5) vc(5) 0 0;
				height: 100%;
				min-height: vc(350);
				object-fit: cover;
			}

			@include tablet {
				flex-shrink: 0;
				width: vc(330);

				img {
					border-radius: vc(5) 0 0 vc(5);
				}
			}
		}

		&__content {
			position: relative;
			z-index: 5;
			padding: vc(34) vc(25);

			@include tablet {
				padding: vc(34) vc(35);
			}
		}

		&__icon {
			position: absolute;
			right: vc(29);
			top: vc(34);
			z-index: -1;
			width: vc(126);
			height: vc(120);
			background: url("../images/atom.svg") 50% 50% no-repeat;
			background-size: contain;

			@include tablet {
				right: vc(35);
			}
		}

		&__title {
			margin: 0 0 vc(20);
			font-family: $font-secondary;
			font-weight: 300;
			font-size: vc(20);
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}

		&__list {
			position: relative;
			padding-left: vc(29);
			font-family: $font-secondary;
			font-weight: 300;
			font-size: vc(16);
			letter-spacing: 0.05em;
			text-transform: uppercase;

			&::before {
				content: "";
				position: absolute;
				left: vc(4);
				top: 0;
				bottom: 0;
				width: 1px;
				background: #e0e0e0;
			}

			li {
				position: relative;

				&::before {
					content: "";
					position: absolute;
					left: vc(-29);
					top: vc(8);
					border-radius: 50%;
					width: vc(9);
					height: vc(9);
					background: #006bbb;
				}

				+ li {
					margin-top: vc(20);
				}
			}
		}

		@include tablet {
			display: flex;
			margin: vc(40) 0 0;
		}

		@include desktop {
			flex-shrink: 0;
			margin: 0 0 0 vc(70);
			width: vc(670);
		}
	}

	&__info {
		margin: vc(20) vc(-15) 0;
		padding: vc(10) vc(25);
		font-family: $font-secondary;
		font-weight: 300;
		font-size: vc(16);
		letter-spacing: 0.05em;
		text-align: center;
		text-transform: uppercase;
		color: $color-white;
		background: #006bbb;

		@include tablet {
			margin: vc(40) 0 0;
			padding: vc(6) vc(38);
			font-size: vc(20);
		}

		@include desktop {
			padding: vc(21);
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
