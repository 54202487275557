.quote {
	border-left: 2px solid $color-science-blue;
	padding: vc(30);
	color: $font-primary;
	background: $color-white;

	&__item {
		display: flex;
		margin-bottom: vc(10);
		font-size: vc(15);
		line-height: vc(22);

		&__label {
			margin-right: vc(10);
			font-weight: 700;
		}
	}

	&__text {
		margin: vc(20) 0;
	}
}
