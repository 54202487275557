.search {
	margin-bottom: vc(60);

	&__find {
		margin-bottom: vc(20);
		border-left: solid 2px #006bbb;
		padding: vc(30);
		background: $color-white;

		&__text {
			margin-bottom: vc(10);
			font-size: vc(16);

			@include tablet {
				font-size: vc(20);
			}
		}

		&__label {
			font-size: vc(16);
			color: #9f9f9f;
		}

		@include tablet {
			margin-bottom: vc(40);
		}
	}

	&__item {
		&__title {
			margin: 0 0 vc(20);
			font-family: $font-secondary;
			font-weight: 300;
			font-size: vc(16);
			line-height: 150%;
			letter-spacing: 0.05em;
			text-transform: uppercase;

			&__highlight {
				font-weight: 400;
				color: #006bbb;
			}

			&:last-child {
				margin-bottom: 0;
			}

			@include tablet {
				margin-bottom: vc(40);
				font-size: vc(24);
			}
		}

		& + & {
			margin-top: vc(20);
			border-top: solid 1px #e0e0e0;
			padding-top: vc(20);
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
