.licenses {
	margin-bottom: vc(60);

	&__slider {
		position: relative;

		.swiper-container {
			overflow: hidden;
		}
	}

	&__image {
		text-align: center;

		img {
			width: vc(260);
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
