.other-services {
	margin-bottom: vc(60);

	&__row {
		@include tablet {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vc(-15);
		}
	}

	&__col {
		& + & {
			margin-top: vc(20);
		}

		@include tablet {
			padding: 0 vc(15);
			width: 50%;

			& + & {
				margin-top: 0;
			}
		}
	}

	&__item {
		position: relative;
		z-index: 1;
		display: block;
		padding: vc(20);
		min-height: vc(120);

		&__background {
			position: absolute;
			right: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background: 50% 50% no-repeat;
			background-size: cover;
		}

		&__title {
			font-family: $font-secondary;
			font-weight: 300;
			font-size: vc(26);
			letter-spacing: 0.1em;
			text-transform: uppercase;

			@include tablet {
				font-size: vc(30);
			}
		}

		&__line {
			position: relative;
			display: none;
			flex-shrink: 0;
			margin-top: auto;
			height: 1px;
			max-width: vc(174);
			background: $color-science-blue;

			&::before {
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				border-radius: 50%;
				width: vc(9);
				height: vc(9);
				background: $color-science-blue;
				transform: translateY(-50%);
			}

			@include tablet {
				display: block;
			}
		}

		&__arrow {
			position: absolute;
			right: vc(15);
			bottom: vc(15);
			color: #ff474e;

			svg {
				width: vc(40);
				height: vc(40);
			}
		}

		&--dark {
			color: $color-white;

			&:hover,
			&:active {
				color: #ff474e;
			}
		}

		&--dark &__line {
			background: #ff474e;

			&::before {
				background: #ff474e;
			}
		}

		&--dark &__arrow {
			color: $color-white;
		}

		@include tablet {
			display: flex;
			flex-direction: column;
			padding: vc(50) vc(50) vc(75);
			height: vc(240);
			min-height: 0;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
