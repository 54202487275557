.header {
	&__location {
		&__label {
			margin-left: vc(10);
			border-bottom: 1px dashed #006bbb;
			font-weight: 700;
		}
	}

	&__burger {
		position: relative;
		display: block;
		outline: none;
		border: none;
		padding: 0;
		width: 40px;
		height: 40px;
		background: #006bbb;

		i {
			position: absolute;
			left: 10px;
			top: 13px;
			border-radius: 2px;
			width: 20px;
			height: 1px;
			background: $color-white;

			+ i {
				top: 20px;
				width: 15px;

				+ i {
					top: 27px;
					width: 20px;
				}
			}
		}

		@include desktop {
			display: none;
		}
	}

	&__logo {
		margin: 0 auto 0 vc(20);

		img {
			width: vc(92);
		}

		@include desktop {
			margin: auto;

			img {
				width: vc(128);
			}
		}

		@include desktop {
			margin: 0 vc(30) 0 0;

			img {
				width: vc(200);
			}
		}
	}

	&__phones {
		flex-shrink: 0;
		margin: 0 0 vc(20);
		text-align: center;

		&__item {
			margin-bottom: vc(3);
			font-size: vc(16);
			text-align: right;

			@include tablet {
				font-size: vc(20);
			}

			@include desktop {
				padding: 0 vc(22);
			}
		}

		&__schedule {
			margin-top: vc(7);
			border-top: solid 1px #e0e0e0;
			padding-top: vc(10);
			font-size: vc(13);

			@include tablet {
				font-size: vc(14);
			}
		}

		@include tablet {
			margin: 0 vc(40) 0 0;
		}

		@include desktop {
			margin: 0 vc(30) 0 0;
		}
	}

	&__email {
		margin-bottom: vc(25);
		text-align: center;

		&__item {
			margin-bottom: vc(7);
		}

		&__info {
			font-size: vc(13);

			@include tablet {
				font-size: vc(15);
			}
		}

		&__button {
			margin-top: vc(10);

			a {
				display: inline-block;
				vertical-align: middle;
				border-radius: vc(100);
				padding: vc(8) vc(13);
				font-weight: 700;
				font-size: vc(13);
				color: $color-white;
				background: #006bbb;

				&:hover {
					background: #0078d1;
				}

				&:active {
					background: #005ea5;
				}
			}

			@include tablet {
				a {
					padding: vc(8) vc(25);
				}
			}
		}

		@include tablet {
			margin-bottom: 0;
		}
	}

	&__lk {
		display: flex;
		font-size: vc(14);
		letter-spacing: 0.1em;
		text-transform: uppercase;

		li {
			+ li {
				margin-left: -1px;
			}

			a {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				border: solid 1px #111;
				width: vc(40);
				height: vc(40);

				svg {
					width: vc(30);
					height: vc(30);
				}
			}
		}

		&__count {
			position: absolute;
			right: vc(3);
			top: vc(3);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			width: vc(15);
			height: vc(15);
			font-size: vc(10);
			color: $color-white;
			background: #ff474e;

			@include desktop {
				left: vc(25);
				right: auto;
				top: vc(4);
				width: vc(18);
				height: vc(18);
				font-size: vc(13);
			}
		}

		@include desktop {
			margin-left: auto;

			li {
				+ li {
					margin-left: vc(30);
				}

				a {
					border: none;
					width: auto;
					height: auto;

					svg {
						margin-right: vc(5);
						width: vc(50);
						height: vc(50);
					}
				}
			}
		}
	}

	&__catalog {
		position: relative;
		flex-shrink: 0;

		&__button {
			display: flex;
			color: $color-white;

			&__icon {
				display: none;
				align-items: center;
				justify-content: center;
				border: 1px solid #ff474e;
				border-radius: vc(5) 0 0;
				width: vc(64);
				background: $color-white;

				svg {
					width: vc(24);
					height: vc(24);
				}

				@include tablet {
					display: flex;
				}
			}

			&__label {
				display: flex;
				align-items: center;
				border-radius: vc(5) vc(5) 0 0;
				padding: vc(14) vc(19);
				width: 100%;
				font-family: $font-secondary;
				font-weight: 500;
				letter-spacing: 0.1em;
				text-transform: uppercase;
				color: $color-white;
				background: #ff474e;

				svg {
					position: absolute;
					right: vc(20);
					top: 50%;
					margin-left: vc(15);
					margin-top: vc(-8);
					width: vc(16);
					height: vc(16);
					transition: $duration-normal;

					@include tablet {
						position: static;
						right: auto;
						top: auto;
						margin-top: 0;
					}
				}

				.is-show-dropdown & {
					svg {
						transform: rotate(180deg);
					}
				}

				@include tablet {
					border-radius: 0;
					width: auto;
				}
			}
		}

		&__dropdown {
			position: absolute;
			left: 0;
			top: 100%;
			z-index: 15;
			width: 100%;
			opacity: 0;
			visibility: hidden;
			transition: $duration-normal;

			.is-show-dropdown & {
				opacity: 1;
				visibility: visible;
			}
			
			@include tablet {
				width: vc(270);
			}

			@include desktop {
				width: vc(335);
			}
		}

		&__menu {
			background: $color-white;

			> li {
				> a {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: -1px;
					border: 1px solid #e0e0e0;
					padding: vc(10.5) vc(19);
					font-size: vc(14);
					color: #111;

					svg {
						flex-shrink: 0;
						width: vc(16);
						height: vc(16);
						color: #ff474e;
						transition: $duration-normal;
					}
				}
				

				&.is-show-sub-dropdown {
					> a {
						svg {
							transform: rotate(180deg);
						}
					}
				}
			}

			@include tablet {
				> li {
					> a {
						svg {
							transform: rotate(-90deg);
							opacity: 0;
							visibility: hidden;
						}
					}
					
	
					&.is-show-sub-dropdown {
						> a {
							font-weight: 700;
	
							svg {
								opacity: 1;
								visibility: visible;
								transform: rotate(-90deg);
							}
						}
					}
				}
			}
		}

		&__sub-dropdown {
			display: none;
			margin: -1px 0;
			border: 1px solid #e0e0e0;
			opacity: 0;
			visibility: hidden;
			transition: $duration-normal;

			.is-show-sub-dropdown & {
				display: block;
				opacity: 1;
				visibility: visible;
				pointer-events: all;
			}

			@include tablet {
				position: absolute;
				left: 100%;
				top: 0;
				display: block;
				margin: -1px 0 0 -1px;
				width: vc(421);
				background: $color-white;
				pointer-events: none;
			}

			@include desktop {
				width: vc(836);
			}
		}

		&__head {
			display: none;
			align-items: center;
			justify-content: space-between;
			margin: -1px -1px 0;
			padding: vc(10) vc(30);
			color: $color-white;
			background: #ff474e;

			&__title {
				font-family: $font-secondary;
				font-weight: 500;
				font-size: vc(16);
				letter-spacing: 0.1em;
				text-transform: uppercase;
			}

			&__link {
				display: flex;
				align-items: center;
				font-family: $font-secondary;
				font-weight: 500;
				font-size: vc(13);
				letter-spacing: 0.1em;
				text-transform: uppercase;

				&__text {
					display: none;

					@include desktop {
						display: inline;
					}
				}

				svg {
					flex-shrink: 0;
					margin-left: vc(5);
					width: vc(16);
					height: vc(16);
					transform: rotate(-90deg);
				}
			}

			&:hover {
				color: #ececec;
			}

			@include tablet {
				display: flex;
			}
		}

		&__list {
			padding: vc(20) vc(24);
			font-size: vc(13);

			ul {
				+ ul {
					margin-top: vc(15);
					border-top: solid 1px #e0e0e0;
					padding-top: vc(15);
				}

				li {
					+ li {
						margin-top: vc(5);
					}
				}
			}

			&--desktop {
				display: none;
			}

			@include tablet {
				padding: vc(25) vc(30) vc(30);

				&--mobile {
					display: none;
				}

				&--desktop {
					display: block;
				}
			}

			@include desktop {
				.swiper-wrapper {
					margin: 0 vc(-15);
					width: auto;
				}
	
				.swiper-slide {
					flex: 1;
					padding: 0 vc(15);
					box-sizing: border-box;
				}

				.pager-dots {
					display: none;
				}
			}
		}
	}

	&__search {
		position: relative;
		width: 100%;

		&__field {
			display: block;
			outline: none;
			border: 1px solid #ff474e;
			border-radius: 0;
			padding: 0 vc(20);
			width: 100%;
			height: vc(52);
			font-size: vc(15);
			background: $color-white;

			&::placeholder {
				color: #9f9f9f;
			}

			@include tablet {
				border-radius: 0 vc(5) 0 0;
			}
		}

		&__submit {
			position: absolute;
			right: vc(20);
			top: 50%;
			outline: none;
			border: none;
			padding: 0;
			width: vc(20);
			height: vc(20);
			color: #9f9f9f;
			background: none;
			transform: translateY(-50%);

			&:hover {
				color: #0078d1;
			}
		}

		@include desktop {
			width: vc(335);
		}
	}

	&__top {
		margin-bottom: vc(25);
		font-size: vc(14);

		&__container {
			@include tablet {
				display: flex;
				justify-content: space-between;
			}

			@include desktop {
				padding: vc(10) 0;
			}
		}

		&__menu {
			display: flex;
			justify-content: space-between;
			margin-bottom: vc(20);

			ul {
				li {
					+ li {
						margin-top: vc(10);
					}
				}
			}

			@include tablet {
				justify-content: flex-start;
				margin-bottom: 0;

				ul {
					display: flex;
	
					+ ul {
						margin-left: vc(25);
					}
	
					li {
						+ li {
							margin: 0 0 0 vc(25);
						}
					}
				}
			}

			@include desktop {
				margin-bottom: 0;

				ul {
					+ ul {
						margin-left: vc(70);
					}

					li {
						+ li {
							margin-left: vc(70);
						}
					}
				}
			}
		}

		@include tablet {
			margin-bottom: vc(30);
			font-size: vc(15);
		}

		@include desktop {
			margin-bottom: 0;
			background: #e4eefa;
		}
	}

	&__middle {
		&__container {
			position: relative;
			display: flex;
			align-items: center;
			padding: vc(15) 0;

			@include desktop {
				padding: vc(30) 0;
			}
		}
	}

	&__bottom {
		&__container {
			@include tablet {
				display: flex;
				align-items: center;
			}
		}

		&__menu {
			display: flex;
			justify-content: space-between;
			width: 100%;

			ul {
				+ ul {
					text-align: right;
				}

				li {
					+ li {
						margin-top: vc(10);
					}

					a {
						&.is-red {
							color: #ff474e;
						}

						&.is-blue {
							color: #006bbb;
						}
					}
				}
			}

			@include tablet {
				display: block;
				margin-left: auto;
				width: auto;

				ul {
					+ ul {
						margin: vc(10) 0 0;
						text-align: left;
					}
				}
			}

			@include desktop {
				ul {
					display: flex;

					+ ul {
						margin: 0 0 0 vc(30);
					}

					li {
						+ li {
							margin: 0 0 0 vc(30);
						}
					}
				}
			}
		}

		@include desktop {
			background: #e4eefa;
		}
	}

	&__dropdown {
		position: absolute;
		left: 0;
		top: vc(55);
		z-index: 15;
		padding: vc(25);
		width: 100%;
		background: $color-white;
		opacity: 0;
		visibility: hidden;
		transition: $duration-normal;

		&__row {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@include tablet {
				flex-direction: row;
				align-items: center;
				padding: 0 vc(14);
			}
		}

		.is-show-menu & {
			opacity: 1;
			visibility: visible;
		}

		@include tablet {
			padding: vc(30) vc(25);
		}
	}
}
