.applications {
	margin-bottom: vc(60);

	&__list {
		position: relative;

		.swiper-slide {
			height: auto;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		height: 100%;
		background: $color-white;

		&__image {
			img {
				width: 100%;
				height: vc(140);
				object-fit: cover;
			}

			@include tablet {
				img {
					height: vc(160);
				}
			}
		}

		&__title {
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: center;
			padding: vc(15) vc(12);
			font-size: vc(13);
			letter-spacing: 0.1em;
			text-align: center;
			text-transform: uppercase;

			@include tablet {
				padding: vc(15);
			}
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
