.footer {
	position: relative;
	padding: vc(70) 0 vc(40);
	font-size: vc(14);

	.container {
		@include desktop {
			position: relative;
		}
	}

	&__up {
		position: absolute;
		left: 50%;
		top: 0;
		margin-left: vc(-15);
		width: vc(30);
		height: vc(30);
		color: #006bbb;

		@include desktop {
			left: 100%;
			top: vc(20);
			margin-left: 0;
		}

		@media (min-width: 1400px) {
			margin-left: vc(45);
		}
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: vc(20);

		&__line {
			position: relative;
			display: none;
			width: vc(200);
			height: 1px;
			background: #006bbb;

			&::before {
				content: "";
				position: absolute;
				top: 50%;
				border-radius: 50%;
				width: vc(9);
				height: vc(9);
				background: #006bbb;
				transform: translateY(-50%);
			}

			&--left {
				&::before {
					left: 0;
				}
			}

			&--right {
				&::before {
					right: 0;
				}
			}

			@include tablet {
				display: block;
			}

			@include desktop {
				width: vc(300);
			}
		}

		&__image {
			margin: 0 auto;

			img {
				width: vc(200);
			}
		}

		@include tablet {
			margin-bottom: vc(40);
		}
	}

	&__container {
		@include desktop {
			display: flex;
		}
	}

	&__left {
		width: 100%;

		&__top {
			margin-bottom: vc(30);

			@include tablet {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			@include desktop {
				margin-bottom: vc(40);
			}
		}
	}

	&__right {
		margin-top: vc(30);

		&__right {
			width: 100%;
		}

		&__bottom {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		@include tablet {
			display: flex;
			align-items: flex-start;
		}

		@include desktop {
			display: block;
			flex-shrink: 0;
			margin: 0 0 0 vc(90);
			width: vc(470);
		}
	}

	&__copy {
		margin-bottom: vc(20);
		text-align: center;
		color: #9f9f9f;

		@include tablet {
			margin-bottom: 0;
			text-align: left;
			width: vc(236);
		}
	}

	&__legal {
		display: flex;
		justify-content: space-between;

		li {
			+ li {
				margin-left: vc(15);
			}
		}
	}

	&__menu {
		display: flex;
		margin-bottom: vc(30);

		&__col {
			width: 50%;

			&:nth-child(1) {
			}

			&:nth-child(2) {
				ul {
					li {
						display: flex;
						justify-content: flex-end;
					}
				}
			}

			@include tablet {
				display: flex;
				justify-content: space-between;
				width: auto;

				&:nth-child(1) {
					width: 55%;

					ul {
						&:nth-child(2) {
							margin: 0 auto 0 vc(78);
							width: auto;
						}
					}
				}

				&:nth-child(2) {
					width: 45%;

					ul {
						display: block;
					}
				}
			}

			@include desktop {
				&:nth-child(1) {
					ul {
						&:nth-child(2) {
							margin: 0 auto 0 vc(52);
						}
					}
				}
			}
		}

		ul {
			white-space: nowrap;

			+ ul {
				margin-top: vc(10);
			}

			li {
				+ li {
					margin-top: vc(10);
				}
			}
		}

		@include tablet {
			ul {
				white-space: normal;

				+ ul {
					margin-top: 0;
				}
			}
		}

		@include desktop {
			margin-bottom: vc(37);
		}
	}

	&__link {
		a {
			color: #9f9f9f;

			&:hover {
				color: #0078d1;
			}
		}
	}

	&__subscribe {
		position: relative;
		margin-bottom: vc(30);

		&__field {
			input {
				outline: none;
				border: 1px solid #e0e0e0;
				padding: 0 vc(137) 0 vc(15);
				width: 100%;
				height: vc(40);
				font-size: vc(14);
				background: $color-white;

				&::placeholder {
					color: #9f9f9f;
				}
			}
		}

		&__submit {
			position: absolute;
			right: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			outline: none;
			border: 1px solid #ff474e;
			padding: 0 vc(20);
			height: 100%;
			font-family: $font-secondary;
			font-size: vc(13);
			line-height: 1.5;
			letter-spacing: 0.05em;
			text-align: center;
			text-transform: uppercase;
			color: #ff474e;
			background: $color-white;

			&:hover {
				border-color: #ff5d63;
				color: #ff5d63;
			}

			&:active {
				border-color: #ef3a41;
				color: #ef3a41;
			}
		}

		@include tablet {
			flex-shrink: 0;
			margin: 0 vc(30) 0 0;
		}

		@include desktop {
			margin: 0 0 vc(40);
			width: auto;
		}
	}

	&__contacts {
		margin-bottom: vc(30);

		li {
			position: relative;
			padding-left: vc(29);

			+ li {
				margin-top: vc(8);
			}

			a {
				+ a {
					margin-left: vc(22);
				}
			}
		}

		&__icon {
			position: absolute;
			left: 0;
			top: 50%;
			width: vc(16);
			height: vc(16);
			color: #e0e0e0;
			transform: translateY(-50%);
		}

		@include tablet {
			margin-bottom: vc(20);

			li {
				a {
					+ a {
						margin-left: vc(30);
					}
				}
			}
		}

		@include desktop {
			margin-bottom: vc(38);
		}
	}

	&__socials {
		display: flex;

		li {
			+ li {
				margin-left: vc(8);
			}

			a {
				svg {
					width: vc(24);
					height: vc(24);
				}
			}
		}
	}

	@include desktop {
		padding: 0 0 vc(46);
	}
}
