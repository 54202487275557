.standards {
	margin-bottom: vc(60);

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vc(-5) vc(-10);

		@include tablet {
			margin: 0 vc(-15) vc(-30);
		}
	}

	&__col {
		margin-bottom: vc(10);
		padding: 0 vc(5);
		width: 50%;

		@include tablet {
			margin-bottom: vc(30);
			padding: 0 vc(15);
		}

		@include desktop {
			width: 33.33334%;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: vc(26);
		height: 100%;
		font-size: vc(18);
		letter-spacing: 0.1em;
		text-align: center;
		text-transform: uppercase;
		background: $color-white;
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
