.become-dealer {
	margin-bottom: vc(60);

	&__container {
		@include desktop {
			display: flex;
		}
	}

	&__content {
		width: 100%;
	}

	&__right {
		margin: vc(40) 0 0;

		@include tablet {
			margin: vc(40) auto 0;
			width: vc(470);
		}

		@include desktop {
			flex-shrink: 0;
			margin: 0 0 0 vc(125);
		}
	}

	&__text {
		font-size: vc(14);

		@include tablet {
			font-size: vc(15);
		}
	}

	&__person {
		margin: 0 vc(-15);
		border-radius: vc(5);
		background: $color-white;

		&__image {
			img {
				border: solid 2px $color-white;
				border-radius: vc(5) vc(5) 0 0;
				width: 100%;
				height: 100%;
				min-height: vc(350);
				object-fit: cover;
			}

			@include tablet {
				flex-shrink: 0;
				width: vc(270);

				img {
					border-radius: vc(5) 0 0 vc(5);
					min-height: vc(322);
				}
			}
		}

		&__content {
			padding: vc(30) vc(20);
		}

		&__name {
			margin-bottom: vc(4);
			font-size: vc(16);
		}

		&__role {
			margin-bottom: vc(15);
			font-size: vc(14);
			color: #4A4A4A;

			@include tablet {
				margin-bottom: vc(20);
			}
		}

		&__text {
			margin-bottom: vc(15);
			font-size: vc(14);

			@include tablet {
				margin-bottom: vc(20);
			}
		}

		&__phone {
			margin-bottom: vc(10);
			font-size: vc(18);
		}

		&__email {
			font-size: vc(16);
		}

		@include tablet {
			display: flex;
			margin: 0;
		}
	}

	&__button {
		margin-top: vc(40);

		.button {
			width: 100%;
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
