.success {
	&__item {
		display: flex;
		width: 100%;

		&__icon {
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
			width: vc(124);
			background: $color-science-blue;

			svg {
				width: vc(62);
				height: vc(62);
			}
		}

		&__content {
			display: flex;
			align-items: center;
			padding: vc(38) vc(16);
			width: 100%;
			background: $color-white;

			.subtitle {
				margin-bottom: 0;
			}

			@include tablet {
				padding: vc(47) vc(30);
			}
		}

		@include desktop {
			width: vc(670);
		}
	}
}
