.input {
	margin-bottom: vc(15);

	&__field {
		textarea,
		input {
			display: block;
			outline: none !important;
			border: 1px solid #e0e0e0;
			border-radius: vc(5);
			padding: 0 vc(20);
			width: 100%;
			height: vc(52);
			font-size: vc(15);
			line-height: 1.5;
			background: $color-white;

			&::placeholder {
				color: #9f9f9f;
			}
		}

		textarea {
			padding-top: vc(15);
			height: vc(252);
			resize: none;
		}

		&--mini {
			textarea {
				height: vc(140);
			}
		}

		@include tablet {
			&--big {
				textarea {
					height: vc(320);
				}
			}
		}
	}
}
