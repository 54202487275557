.system-sales {
	margin-bottom: vc(70);

	&__slider {
		@include tablet {
			display: none;
		}
	}

	&__list {
		display: none;
		flex-wrap: wrap;
		margin: 0 vc(-15);

		@include tablet {
			display: flex;
		}
	}

	&__col {
		margin-bottom: vc(30);
		padding: 0 vc(15);
		width: 50%;

		@include desktop {
			width: 33.33334%;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		height: 100%;

		&__icon {
			z-index: 1;
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
			margin-right: vc(-37);
			margin-left: vc(-10);
			border: vc(10) solid #f0f7ff;
			border-radius: 50%;
			width: vc(80);
			height: vc(80);
			background: $color-science-blue;

			img {
				width: vc(40);
			}

			@include tablet {
				width: vc(100);
				height: vc(100);
			}
		}

		&__text {
			border: 1px solid $color-alto;
			padding: vc(30) vc(18) vc(30) vc(46);
			width: 100%;
			height: 100%;
			background: $color-white;

			@include tablet {
				padding: vc(30) vc(8) vc(30) vc(38);
				text-align: center;
			}

			@include desktop {
				padding: vc(30) vc(28) vc(30) vc(58);
			}
		}

		& + & {
			margin-top: vc(30);
		}
	}
}
