.form {
	&__row {
		@include desktop {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vc(-15);
		}
	}

	&__col {
		@include desktop {
			padding: 0 vc(15);
			width: 50%;
		}
	}

	&__footer {
		margin-top: vc(40);

		@include desktop {
			display: flex;
			align-items: center;
			margin-top: vc(25);
		}
	}

	&__submit {
		text-align: center;

		.button {
			width: 100%;
		}

		@include tablet {
			.button {
				width: vc(569);
			}
		}

		@include desktop {
			padding-right: vc(15);
			width: 50%;

			.button {
				width: 100%;
			}
		}
	}

	&__agree {
		margin-top: vc(15);
		font-size: vc(13);
		text-align: center;

		a {
			text-decoration: underline;
			color: #2f2c7c;

			&:hover {
				color: #0078d1;
			}
		}

		@include tablet {
			margin-top: vc(30);
		}

		@include desktop {
			margin-top: 0;
			padding-left: vc(15);
			width: 50%;
			text-align: left;
		}
	}
}
