.video-list {
	&__slider {
		@include tablet {
			display: none;
		}
	}

	&__row {
		display: none;
		flex-wrap: wrap;
		margin: 0 0 vc(-30);
		padding: 0 vc(45);

		@include tablet {
			display: flex;
		}

		@include desktop {
			margin: 0 vc(-15) vc(-30);
			padding: 0;
		}
	}

	&__col {
		margin-bottom: vc(30);
		padding: 0 vc(15);
		width: 50%;

		@include desktop {
			width: 25%;
		}
	}

	&__item {
		display: flex;

		&__image {
			position: relative;
			flex-shrink: 0;
			margin-right: vc(10);
			border-radius: vc(10);
			width: vc(140);
			height: vc(104);
			background: 50% 50% no-repeat;
			background-size: cover;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				border-radius: vc(10);
				width: 100%;
				height: 100%;
				background: linear-gradient(
					238.42deg,
					rgba(4, 56, 114, 0.75) 0.29%,
					rgba(19, 85, 163, 0.75) 37.62%,
					rgba(24, 86, 169, 0.75) 48.08%,
					rgba(15, 81, 160, 0.75) 59.98%,
					rgba(5, 57, 117, 0.75) 94.12%
				);
			}

			@include tablet {
				margin: 0 0 vc(10);
				padding-bottom: 75%;
				width: auto;
				height: auto;
			}
		}

		&__icon {
			position: absolute;
			left: 50%;
			top: 50%;
			width: vc(40);
			height: vc(40);
			transform: translate(-50%, -50%);

			@include tablet {
				width: vc(80);
				height: vc(80);
			}
		}

		&__title {
			font-size: vc(14);
		}

		& + & {
			margin-top: vc(15);
		}

		@include tablet {
			display: block;

			& + & {
				margin-top: 0;
			}
		}
	}
}
