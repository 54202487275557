.popular {
	margin-bottom: vc(60);

	&__carousel {
		position: relative;

		.swiper-slide {
			height: auto;
		}

		.card__top {
			padding-bottom: 69%;

			@include tablet {
				padding-bottom: 60.6%;
			}

			@include desktop {
				padding-bottom: 54.1%;
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
