.sort {
	flex-shrink: 0;
	margin-bottom: vc(20);
	font-size: vc(14);

	&__label {
		flex-shrink: 0;
		margin-bottom: vc(10);
		color: #9f9f9f;

		@include tablet {
			margin: 0 vc(20) 0 0;
		}
	}

	&__list {
		display: flex;

		li {
			+ li {
				margin-left: vc(15);
			}

			a {
				color: #006bbb;

				&:hover {
					color: #0078d1;
				}

				&.is-active {
					text-decoration: underline;
				}
			}
		}
	}

	@include tablet {
		display: flex;
		margin-bottom: vc(25);
	}
}
