.payment {
	&__content {
		margin-bottom: vc(30);

		@include tablet {
			margin-bottom: vc(40);
		}
	}

	&__text {
		margin-bottom: vc(20);
	}

	&__quote {
		width: 100%;

		@include tablet {
			width: vc(568);
		}
	}
}
