.service-center {
	margin-bottom: vc(60);

	&__slider {
		margin-bottom: vc(40);

		@include tablet {
			display: none;
		}
	}

	&__list {
		display: none;
		flex-wrap: wrap;
		margin: 0 vc(-15) vc(30);

		&__col {
			margin-bottom: vc(30);
			padding: 0 vc(15);
			width: 50%;

			@include desktop {
				width: 33.3334%;
			}
		}

		@include tablet {
			display: flex;
		}
	}

	&__item {
		display: flex;
		border-radius: 2px;
		background: $color-white;

		&__image {
			flex-shrink: 0;
			width: vc(100);

			img {
				border: solid 2px $color-white;
				border-radius: 2px;
				height: vc(160);
				object-fit: cover;
			}

			@include tablet {
				width: vc(160);
			}
		}

		&__content {
			display: flex;
			align-items: center;
			padding: vc(25);

			@include tablet {
				padding: vc(30);
			}
		}

		&__text {
			font-family: $font-secondary;
			font-weight: 300;
			font-size: vc(16);
			letter-spacing: 0.1em;
			text-transform: uppercase;
		}

		& + & {
			margin-top: vc(20);
		}
	}

	&__title {
		margin: 0 0 vc(20);
		font-family: $font-secondary;
		font-weight: 300;
		font-size: vc(16);
		letter-spacing: 0.1em;
		text-transform: uppercase;

		@include tablet {
			font-size: vc(20);
		}
	}

	&__info {
		margin: 0 vc(-15) vc(40);
		padding: vc(30) vc(15);
		text-align: center;
		background: #eff7ff url("../images/service-center-info-mobile.png") 50% 50% no-repeat;
		background-size: cover;

		@include tablet {
			margin: 0 0 vc(100);
			padding: vc(30);
			background-image: url("../images/service-center-info.png");
		}

		@include desktop {
			padding: vc(30) vc(100);
		}
	}

	&__range {
		&__slider {
			@include tablet {
				display: none;
			}
		}

		&__row {
			display: none;

			@include tablet {
				display: block;
			}

			@include desktop {
				display: flex;
				margin: 0 vc(-15);
			}
		}

		&__col {
			& + & {
				margin-top: vc(20);
			}

			@include desktop {
				padding: 0 vc(15);
				width: 50%;

				& + & {
					margin-top: 0;
				}
			}
		}

		&__text {
			font-size: vc(15);

			ul {
				li {
					+ li {
						margin-top: vc(20);
					}
				}
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
