.card {
	display: flex;
	flex-direction: column;
	height: 100%;
	box-sizing: border-box;

	&__top {
		position: relative;
		margin-bottom: vc(15);
		padding-bottom: 69%;
		background: $color-white;

		@include tablet {
			padding-bottom: 60.6%;
		}

		@include desktop {
			padding-bottom: 74%;
		}
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: vc(23) vc(50);
		width: 100%;
		height: 100%;
		text-align: center;

		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}

		@include desktop {
			padding: vc(26) vc(20) vc(20);
		}
	}

	&__favorite {
		position: absolute;
		right: vc(15);
		top: vc(15);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		padding: vc(2) 0 0 vc(2);
		width: vc(36);
		height: vc(36);
		color: $color-white;
		background: #ff474e;

		svg {
			width: vc(23);
			height: vc(23);
		}

		&:hover,
		&:active {
			color: $color-white;
		}

		&.is-active {
			color: #ff474e;
			background: #fef6f6;
		}
	}

	&__basket {
		position: absolute;
		right: vc(15);
		bottom: vc(15);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: vc(36);
		height: vc(36);
		color: $color-white;
		background: #006bbb;

		svg {
			width: vc(16);
			height: vc(16);
		}

		&:hover,
		&:active {
			color: $color-white;
		}
	}

	&__options {
		position: absolute;
		left: vc(10);
		top: vc(10);

		li {
			+ li {
				margin-top: vc(5);
			}

			span {
				display: flex;
				align-items: center;
				justify-content: center;
				border-style: solid;
				border-width: 2px;
				border-radius: 50%;
				width: vc(42);
				height: vc(42);

				svg {
					width: vc(24);
					height: vc(24);
				}
			}
		}

		&__view {
			border-color: #4a4a4a;
		}

		&__star {
			border-color: #ff474e;
		}

		&__notification {
			border-color: #006bbb;
		}

		&__discount {
			border: none !important;

			svg {
				width: 100% !important;
				height: 100% !important;
			}
		}
	}

	&__title {
		margin: 0;
		font-weight: 400;
		font-size: vc(16);
		text-transform: uppercase;
	}

	&__brand {
		margin-top: vc(10);
		color: #006bbb;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		margin-top: auto;
		padding-top: vc(15);
		font-size: vc(14);
	}

	&__more {
	}

	&__price {
		color: #4a4a4a;
	}
}
