:root {
	--width-base: 320;
	--width-current: 100vmin;
	--width-multiplier: var(--width-current) / var(--width-base);

	@include tablet {
		--width-base: 768;
		--width-current: 768px;
	}

	@include desktop {
		--width-base: 1340;
		--width-current: 100vw;
	}

	@include desktop-xl {
		--width-base: 1;
		--width-current: 1px;
	}
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	position: relative;
	min-height: 100vh;
	box-sizing: border-box;
	font-family: $font-primary;
	scroll-behavior: smooth;

	&.has-cursor,
	&.has-cursor * {
		// stylelint-disable-next-line
		cursor: none !important;
	}

	&.is-lock-scroll,
	&.is-lock-scroll body {
		overflow: hidden;
	}
}

body {
	-webkit-font-smoothing: antialiased;
	font-size: vc(14);
	line-height: 1.5;
	text-decoration-skip: objects;
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
	color: #111;
	-webkit-tap-highlight-color: transparent;
	background: #f0f7ff;

	@include tablet {
		font-size: vc(16);
	}
}

main {
	flex: 1;
}

p {
	margin: 0;
}

a,
button {
	outline: none;
	transition: $duration-normal;
	cursor: pointer;
}

a {
	text-decoration: none;
	color: currentColor;

	&:hover {
		color: #0078d1;
	}
}

svg {
	display: block;
	flex: 0 0 auto;
	width: 100%;
	height: 100%;
	fill: currentColor;
}

figure,
picture {
	display: inline-block;
	margin: 0;
	line-height: 0;

	img {
		width: 100%;
	}
}

img {
	vertical-align: top;
	max-width: 100%;
	user-select: none;
}

img[draggable="false"] {
	pointer-events: none;
	// stylelint-disable-next-line
	-webkit-user-drag: none;
}

fieldset {
	margin: 0;
	border: 0;
	padding: 0;
}

ul,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}

video {
	outline: none;
	width: 100%;
	height: 100%;
}

iframe {
	display: block;
}

.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	border: 0;
	padding: 0;
	text-align: center;
	background-color: transparent;
	transition: $duration-normal;
	user-select: none;

	&__content {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 100%;
		color: currentColor;
		transform: translateZ(0);
	}
}

.js-lazy-load:not(.is-loaded) {
	max-height: 50vh;
}

.site {
	display: flex;
	flex-direction: column;
	min-height: 100vh; // fallback
	// stylelint-disable-next-line
	min-height: var(--vh);
}

/* stylelint-disable */
/*
* Немедленно переместите любую анимацию в конечную точку, если пользователь установил свое устройство в положение "prefers reduced motion".
* Это может привести к плохим(непреднамеренным) последствиям. Удалите по мере необходимости и напишите свой собственный код для prefers-reduced-motion.
*/
@media (prefers-reduced-motion: reduce) {
	*,
	*:before,
	*:after {
		animation-duration: 0.001s !important;
		animation-delay: 0s !important;
		transition-duration: 0.001s !important;
		transition-delay: 0s !important;
	}
}
/* stylelint-enable */
