.supply {
	margin-bottom: vc(60);

	.title {
		margin-bottom: vc(22);

		@include tablet {
			margin-bottom: vc(40);
		}
	}

	&__container {
		@include desktop {
			display: flex;
		}
	}

	&__slider {
		position: relative;
		width: 100%;
		min-width: 0;

		&__item {
			position: relative;
			z-index: 5;
			display: flex;
			padding: vc(40) vc(20) 0;
			height: vc(372);
			color: $color-white;

			&__background {
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				background: 50% 50% no-repeat;
				background-size: cover;

				&--desktop {
					display: none;
				}

				@include tablet {
					&--mobile {
						display: none;
					}
	
					&--desktop {
						display: block;
					}
				}
			}

			&__content {
				width: 100%;
			}

			&__title {
				margin: 0 0 vc(24);
				font-family: $font-secondary;
				font-weight: 300;
				font-size: vc(30);
				letter-spacing: 0.1em;
				text-transform: uppercase;

				@include tablet {
					font-size: vc(36);
				}
			}

			&__line {
				position: relative;
				margin-bottom: vc(24);
				height: 1px;
				background: #ff474e;

				&::before {
					content: "";
					position: absolute;
					right: 0;
					top: 50%;
					border-radius: 50%;
					width: vc(9);
					height: vc(9);
					background: #ff474e;
					transform: translateY(-50%);
				}
			}

			&__text {
				margin-bottom: vc(20);

				@include tablet {
					max-width: vc(277);
				}
			}

			&__link {
				a {
					font-weight: 700;
					color: $color-white;

					svg {
						display: inline-block;
						vertical-align: middle;
						margin-left: vc(5);
						width: vc(16);
						height: vc(16);
					}

					&:hover {
						color: #0078d1;
					}

					&:active {
						color: #005ea5;
					}
				}

				@include tablet {
					a {
						color: #006bbb;
					}
				}
			}

			&__image {
				display: none;
				flex-shrink: 0;
				margin-left: 0;
				width: vc(270);

				img {
					width: 100%;
				}

				@include tablet {
					display: block;
				}

				@include desktop {
					margin-left: vc(70);
					width: vc(360);
				}
			}

			@include tablet {
				align-items: center;
				padding: 0 vc(30) 0;
				color: #111;
			}

			@include desktop {
				padding: 0 vc(32) 0 vc(60);
				height: vc(480);
			}
		}

		&__pager {
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 5;
			border-radius: vc(10) vc(10) 0 0;
			padding: vc(10) vc(17) 0;
			width: 100%;
			background: #eff7ff;

			@include tablet {
				left: vc(30);
				padding: vc(10) vc(40) 0;
				width: auto;
			}

			@include desktop {
				left: vc(60);
			}
		}
	}

	&__news {
		position: absolute;
		right: vc(30);
		bottom: 0;
		z-index: 5;
		display: none;
		border-radius: vc(10) vc(10) 0 0;
		padding: vc(15) vc(35);
		font-size: vc(13);
		text-transform: uppercase;
		color: #ff474e;
		background: #eff7ff;

		&::after {
			content: "";
			position: absolute;
			left: vc(20);
			right: vc(20);
			bottom: 0;
			height: 1px;
			background: currentColor;
		}

		@include tablet {
			display: block;
		}

		@include desktop {
			right: vc(60);
		}
	}

	&__links {
		margin: vc(20) 0 0;

		&__item {
			position: relative;
			display: block;
			flex: 1;
			padding: vc(12) vc(20);
			height: 50%;
			color: $color-white;
			background: 50% 50% no-repeat;
			background-size: cover;

			&__text {
				font-family: $font-secondary;
				font-weight: 300;
				font-size: vc(18);
				letter-spacing: 0.1em;
				text-transform: uppercase;

				@include tablet {
					font-size: vc(20);
				}

				@include desktop {
					font-size: vc(30);
				}
			}

			&__line {
				position: relative;
				display: none;
				margin-top: vc(24);
				height: 1px;
				background: #ff474e;

				&::before {
					content: "";
					position: absolute;
					right: 0;
					top: 50%;
					border-radius: 50%;
					width: vc(9);
					height: vc(9);
					background: #ff474e;
					transform: translateY(-50%);
				}

				@include desktop {
					display: block;
				}
			}

			&__icon {
				position: absolute;
				right: vc(20);
				bottom: vc(5);
				width: vc(40);
				height: vc(40);

				@include tablet {
					right: vc(30);
					bottom: vc(15);
				}

				@include desktop {
					right: vc(15);
				}
			}

			&:hover {
				color: $color-white;
			}

			& + & {
				margin-top: vc(20);
			}

			@include tablet {
				margin: 0 vc(15);
				padding: vc(20);

				& + & {
					margin-top: 0;
				}
			}

			@include desktop {
				margin: 0;
				padding: vc(50);
			}
		}

		@include tablet {
			display: flex;
			margin: vc(30) vc(-15) 0;
		}

		@include desktop {
			display: block;
			flex-shrink: 0;
			margin: 0;
			width: vc(279);
		}
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
