.catalog {
	margin-bottom: vc(60);

	.title {
		margin-bottom: vc(30);

		@include tablet {
			margin-bottom: vc(40);
		}
	}

	&__container {
		@include desktop {
			display: flex;
		}
	}

	&__filter {
		margin-bottom: vc(25);

		@include desktop {
			flex-shrink: 0;
			margin: 0 vc(30) 0 0;
			width: vc(270);
		}
	}

	&__content {
		width: 100%;

		&__line {
			@include tablet {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}
		}
	}

	&__tags {
		margin-bottom: vc(20);

		.show-more__content-wrap {
			max-height: vc(190);

			&::before {
				background: linear-gradient(180deg, rgba(239, 247, 255, 0) 0%, rgba(239, 247, 255, 0.7) 100%);
			}
		}

		&__list {
			display: flex;
			flex-wrap: wrap;
			margin: vc(-5) 0 0 vc(-13);
			font-size: vc(12);
			color: #4a4a4a;

			li {
				margin: vc(5) 0 0 vc(13);

				a {
					display: block;
					border: 1px solid #006bbb;
					padding: vc(7);
				}
			}

			@include tablet {
				margin: vc(-10) 0 0 vc(-10);
				font-size: vc(14);

				li {
					margin: vc(10) 0 0 vc(10);

					a {
						padding: vc(7);
					}
				}
			}
		}

		@include tablet {
			margin-bottom: vc(25);

			.show-more__content-wrap {
				max-height: vc(178);
			}
		}

		@include desktop {
			.show-more__content-wrap {
				max-height: vc(131);
			}
		}
	}

	&__cats {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vc(-15) vc(10);

		li {
			margin-bottom: vc(15);
			padding: 0 vc(15);
			width: 100%;

			a {
				display: block;
				border: 1px solid #006bbb;
				padding: vc(7);
				font-size: vc(14);
				text-align: center;
				color: #4a4a4a;

				&:hover {
					color: #0078d1;
				}
			}
		}

		@include tablet {
			margin-bottom: vc(15);

			li {
				margin-bottom: vc(10);
				width: 33.33334%;
			}
		}
	}

	&__standard {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: vc(15);
		margin-left: vc(-20);
		font-size: vc(14);

		&__label {
			margin-bottom: vc(5);
			margin-left: vc(20);
			color: #9f9f9f;
		}

		&__item {
			display: flex;
			align-items: center;
			margin-bottom: vc(5);
			margin-left: vc(20);

			&__remove {
				flex-shrink: 0;
				margin-left: vc(5);
				width: vc(16);
				height: vc(16);
				color: #006bbb;

				&:hover {
					color: #0078d1;
				}
			}
		}

		@include tablet {
			margin-bottom: vc(20);
		}
	}

	&__description {
		margin-bottom: vc(40);
		font-size: vc(14);

		@include tablet {
			font-size: vc(15);
		}
	}

	&__cards {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vc(-15) vc(-30);

		&__col {
			margin-bottom: vc(30);
			padding: 0 vc(15);
			width: 100%;

			@include tablet {
				width: 50%;
			}

			@include desktop {
				width: 33.33334%;
			}
		}

		@include desktop {
			&--full &__col {
				width: 25%;
			}
		}
	}

	.filter-mini {
		&__item {
			@include desktop {
				.select {
					width: vc(233);
				}
			}
		}

		@include tablet {
			margin-bottom: vc(25);
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include tablet {
		margin-bottom: vc(100);
	}
}
