.reviews-list {
	&__slider {
		@include tablet {
			display: none;
		}
	}

	&__row {
		display: none;

		@include tablet {
			display: block;
		}

		@include desktop {
			display: flex;
			flex-wrap: wrap;
			margin: 0 0 vc(-30) vc(-130);
		}
	}

	&__col {
		& + & {
			margin-top: vc(30);
		}

		@include desktop {
			margin: 0 0 vc(30) vc(130);
			width: vc(470);

			& + & {
				margin-top: 0;
			}
		}
	}

	&__item {
		display: flex;
		align-items: flex-start;

		&__content {
			width: 100%;
		}

		&__title {
			margin: 0 0 vc(10);
		}

		&__role {
			margin: 0 0 vc(10);
			color: #4a4a4a;
		}

		&__text-wrap {
			overflow: hidden;
			max-height: vc(105);

			.is-hide-text & {
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 5;
				display: -webkit-box;
				text-overflow: ellipsis;
			}

			.is-show-text & {
				max-height: none;
			}

			@include tablet {
				max-height: vc(84);

				.is-hide-text & {
					-webkit-line-clamp: 4;
				}

				.is-show-text & {
					max-height: none;
				}
			}
		}

		&__text {
			font-size: vc(14);
		}

		&__more {
			margin-top: vc(15);
			font-family: $font-secondary;
			font-size: vc(13);
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: #2f2c7c;

			a {
				text-decoration: underline;
			}
		}

		&__image {
			display: flex;
			flex-shrink: 0;
			align-items: center;
			margin-left: vc(30);
			width: vc(100);

			img {
				width: 100%;
				max-height: 100%;
				object-fit: contain;
			}

			@include tablet {
				width: vc(170);
				height: vc(230);

				&--empty {
					height: auto;
				}
			}
		}

		@include tablet {
			align-items: center;
		}

		@include desktop {
			align-items: flex-start;
		}
	}
}
