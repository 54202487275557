.contacts {
	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vc(-15) vc(20);

		@include tablet {
			margin: 0 vc(-15);
		}

		@include desktop {
			margin: 0 vc(-15) vc(40);
		}
	}

	&__col {
		margin-bottom: vc(20);
		padding: 0 vc(15);
		width: 100%;

		@include tablet {
			margin-bottom: vc(40);
			width: 50%;
		}

		@include desktop {
			margin-bottom: 0;
			width: 25%;
		}
	}

	&__item {
		&__label {
			margin-bottom: vc(5);
			color: #9f9f9f;
		}

		&__text {
			margin-bottom: vc(10);

			&:last-child {
				margin-bottom: 0;
			}
		}

		&__phone {
			a {
				color: $color-science-blue;

				+ a {
					margin-left: vc(5);
				}
			}

			& + & {
				margin-top: vc(15);

				@include tablet {
					margin-top: vc(20);
				}
			}
		}

		&__mail {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			a {
				position: relative;
				margin-bottom: vc(7);
				color: $color-science-blue;

				&::after {
					content: "";
					position: absolute;
					left: 0;
					bottom: vc(-3);
					border-bottom: 1px dashed $color-science-blue;
					width: 100%;
					height: 1px;
					transition: $duration-normal;
				}

				&:hover {
					&::after {
						opacity: 0;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			& + & {
				margin-top: vc(12);
			}
		}

		&__button {
			margin-top: vc(30);

			button {
				border: 1px solid $color-science-blue;
				border-radius: vc(5);
				padding: vc(15);
				width: vc(270);
				font-size: vc(14);
				letter-spacing: 0.1em;
				text-align: center;
				text-transform: uppercase;
				color: $color-science-blue;
				background: $color-white;

				&:hover {
					border-color: $color-lochmara;
					color: $color-lochmara;
				}
			}
		}

		& + & {
			margin-top: vc(30);
		}
	}

	&__map {
		margin: 0 vc(-15) vc(60);

		div {
			width: 100%;
			height: vc(320);

			iframe {
				border: none;
			}
		}

		@include tablet {
			margin: 0 vc(-39) vc(100);
		}

		@include desktop {
			margin: 0 0 vc(100);
		}
	}

	&__scheme {
		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vc(-15);
		}

		&__col {
			padding: 0 vc(15);
			width: 100%;

			& + & {
				@include devices {
					margin-top: vc(30);
				}
			}

			@include desktop {
				width: 50%;
			}
		}

		&__title {
			display: flex;
			align-items: center;
			margin-bottom: vc(10);

			&__icon {
				flex-shrink: 0;
				margin-right: vc(10);

				img {
					width: vc(24);
				}
			}

			.subtitle {
				margin-bottom: 0;
			}

			@include tablet {
				margin-bottom: vc(13);
			}
		}
	}
}
