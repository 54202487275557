.vacancies {
	&__accordion {
		&__item {
			border-bottom: 1px solid $color-alto;

			&__head {
				position: relative;
				display: block;
				outline: none;
				border: none;
				padding: vc(20) 0;
				width: 100%;
				text-align: left;
				background: none;

				.subtitle {
					position: relative;
					z-index: -1;
					margin-bottom: 0;
				}

				&__arrow {
					position: absolute;
					right: vc(20);
					top: 50%;
					z-index: -1;
					width: vc(15);
					height: vc(15);
					transform: translateY(-50%);

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						border-bottom: 1px solid $color-science-blue;
						border-left: 1px solid $color-science-blue;
						width: 100%;
						height: 100%;
						transform: rotate(-45deg);
						transition: $duration-normal;
					}

					.is-show-content & {
						&::before {
							transform: rotate(135deg);
						}
					}
				}
			}

			&__content {
				display: none;
				padding-bottom: vc(40);

				.is-show-content & {
					display: block;
					animation: accordionShow $duration-normal;
				}
			}
		}
	}

	&__text {
		margin-bottom: vc(20);

		&__sum {
			display: block;
			margin-top: vc(10);
			font-weight: 700;

			@include tablet {
				display: inline-block;
				margin-top: 0;
				margin-left: vc(20);
			}
		}
	}

	&__list {
		ul {
			li {
				position: relative;
				padding-left: vc(20);

				&::before {
					content: "";
					position: absolute;
					left: vc(5);
					top: vc(10);
					border-radius: 50%;
					width: vc(5);
					height: vc(5);
					background: $color-black;
				}

				+ li {
					margin-top: vc(10);
				}
			}
		}
	}
}

@keyframes accordionShow {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
